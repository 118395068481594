import styled from "styled-components";
import react, { useState, useEffect } from 'react';
import { mobile, tablet } from "../../utils/mediaQueries";
import { FaBackspace } from "react-icons/fa";

export const Divider = styled.div`
    width: 100%;
    border-bottom: ${p => p.$isThick ? '2px solid #666' : '1px solid #d9d9d9'};
`;

export const pwEyes = (status, callback) => {
    if (status) {
        return (
            <img
                className='password-view'
                onClick={(e) => {
                    e.stopPropagation();
                    callback();
                }}
                style={{width: '25px', padding: '10px 20px', boxSizing: 'content-box'}}
                src={process.env.PUBLIC_URL + '/img/common_view_ico@3x.png'}
                alt='pw-eye'/>
        )
    } else if (!status) {
        return (
            <img
                className='password-view'
                onClick={(e) => {
                    e.stopPropagation();
                    callback();
                }}
                style={{width: '25px', padding: '10px 20px', boxSizing: 'content-box'}}
                src={process.env.PUBLIC_URL + '/img/common_viewoff_ico@3x.png'}
                alt='pw-eye'/>
        )
    }
}

export const pwEyesAuth = (status, callback, style) => {
    if (status) {
        return (
            <img
                className='password-view'
                onClick={(e) => {
                    e.stopPropagation();
                    callback();
                }}
                style={style ? style : {width: '25px', padding: '10px 20px', boxSizing: 'content-box'}}
                src={process.env.PUBLIC_URL + '/img/common_view_ico@3x.png'}
                alt='pw-eye'/>
        )
    } else if (!status) {
        return (
            <img
                className='password-view'
                onClick={(e) => {
                    e.stopPropagation();
                    callback();
                }}
                style={style ? style : {width: '25px', padding: '10px 20px', boxSizing: 'content-box'}}
                src={process.env.PUBLIC_URL + '/img/common_viewoff_ico@3x.png'}
                alt='pw-eye'/>
        )
    }
}

const NumberInputStyled = styled.div`
    display: flex; flex-direction: column;
    height: 100%;
    align-items: center; justify-content: space-between;

    h4{
        font-size: 1.5rem;
        margin: 2rem 0;
    }

    .number_light{
        display: flex; margin: 2rem 0;
        .light{
            display: inline-block;
            width: 2rem; height: 2rem;
            ${mobile`width: 1rem; height: 1rem;`}
            border-radius: 50%;
            background-color: #b0b0b0;
            margin: 0 5px;

            &.on{
                background-color: #7e6ed6;
            }
        }
    }

    .number_input_btns{
        display: grid; width: 100%;
        grid-template-columns: 1fr 1fr 1fr;

        & > div{
            text-align: center;
            min-width: 1rem;
            padding: 1.25rem 1rem;
            background-color: #f8f8f8;
            font-size: 1.5rem; font-weight: bold;
            ${mobile `font-size: 0.75rem;`}
            margin: 0.25rem;
            border-radius: 10px;
            cursor: pointer;
        }
    }
`;

export const NumberInput = ({callback}) => {

    const [result, setResult] = useState("")

    useEffect(() => {
        // console.log('number result : ', result)

        if (result.length >= 6) {
            callback(result)
        }
    }, [result])

    return(
        <NumberInputStyled className='number_input'>
            <h4>코드를 입력하세요</h4>
            <div className='number_light'>
                <span className={`light ${result[0] ? 'on' : ''}`}></span>
                <span className={`light ${result[1] ? 'on' : ''}`}></span>
                <span className={`light ${result[2] ? 'on' : ''}`}></span>
                <span className={`light ${result[3] ? 'on' : ''}`}></span>
                <span className={`light ${result[4] ? 'on' : ''}`}></span>
                <span className={`light ${result[5] ? 'on' : ''}`}></span>
            </div>
            <div className='number_input_btns'>
                <div onClick={() => setResult(result + '1')}>1</div>
                <div onClick={() => setResult(result + '2')}>2</div>
                <div onClick={() => setResult(result + '3')}>3</div>
                <div onClick={() => setResult(result + '4')}>4</div>
                <div onClick={() => setResult(result + '5')}>5</div>
                <div onClick={() => setResult(result + '6')}>6</div>
                <div onClick={() => setResult(result + '7')}>7</div>
                <div onClick={() => setResult(result + '8')}>8</div>
                <div onClick={() => setResult(result + '9')}>9</div>
                <div onClick={() => setResult("")}>reset</div>
                <div onClick={() => setResult(result + '0')}>0</div>
                <div onClick={() => setResult(result.slice(0, -1))}><FaBackspace /></div>
            </div>
        </NumberInputStyled>
    )

}

export const TextSplitLine = styled.div`
    width: 1px; height: 20px;
    margin: 0 8px;
    background-color: #aaa;
`;

export const useDeviceDetect = (size = 780) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= size);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= size);
      };
  
      window.addEventListener('resize', handleResize);
  
      // 초기 로드 시 한 번 실행
      handleResize();
  
      // cleanup
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [size]);
  
    return isMobile;
  };