import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function ProfitChart({chartNum}) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {

    if (chartNum === null || chartNum < 0) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    const maxChartNum = Math.min(chartNum, 100);
    const realChartNum = 100 - maxChartNum;

    const data = {
      datasets: [
        {
          data: [maxChartNum, realChartNum],
          backgroundColor: ["#16BFB6", "#D8D8D8"],
          borderWidth: 0
        }
      ]
    };

    const textCenter = {
      id: 'textCenter',
      afterDraw: (chart) => {
        const { ctx, chartArea: { top, bottom, left, right} } = chart;
        ctx.save();
        ctx.font = 'bold 30px Helvetica, Arial, sans-serif';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${chartNum}%`, left + right / 2, top + bottom / 1.4);
      }
    };

    chartInstance.current = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        cutout: '70%',
        rotation: -90,
        circumference: 180,
        maintainAspectRatio: false,
        hover : false,
        animation: {
          animateRotate: true,
          animateScale: false,
          easing: 'easeInOutCubic',
        }
      },
      plugins: [textCenter]
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartNum]);

  return (
      <canvas ref={chartRef}></canvas>
  );
}

export default ProfitChart;