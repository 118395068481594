import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000A6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 475px;
  transform: translateX(-50%) translateY(-50%);
  background-color: #222222;
  border-radius: 10px;
  padding: 48px 30px 40px 30px;
  z-index: 51;
  @media (max-width:780px){
    width: 320px;
    padding: 35px 20px 30px 20px;
  }
`

const CloseBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 31px;
  cursor: pointer;
  @media (max-width:780px){
    right: 12px;
  }
`

const ModalTitle = styled.div`
  font-weight: 700;
  color: #fff;
  font-size: 1.75rem;
  line-height: 2.051rem;
  text-align: center;
  @media (max-width:780px){
    font-size: 1.5rem;
    line-height: 1.758rem;
  }
`

const ModalWrap = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  @media (max-width:780px){ 
    margin-top: 12px;
    padding: 25px 15px 15px 15px;
  }
`

const ModalContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  text-align: center;
`

const ModalOtpContainer = styled.div`
  display: flex;
  padding: 34px 0px 33px 0px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width:780px){
    padding: 23px 0px 35px 0px;
  }
`

const ModalOtpNumberWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const ModalOtpNumber = styled.div`
  width: 30px;
  height: 30px;
  border-radius: ${(props) => (props.$modalType === 'otp' ? '10px' : '50%')}; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1F1F1;
  &.active{
    background-color: #16BFB6;
    color: ${(props) => (props.$modalType === 'otp' ? '#fff' : 'transparent')};
    font-weight: 700;
    font-size: 0.938rem;
    line-height: 1.099rem;
  }
  @media (max-width:780px){
    width: 24px;
    height: 24px;
    &.active{
      font-size: 1rem;
      line-height: 1.172rem;
    }
  }
`

const ModalNumberWrap = styled.div`
  width: 355px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
  @media (max-width:780px){
    width: 250px;
    row-gap: 10px;
  }
`

const ModalNumber = styled.div`
  padding: 20px 51px 20px 52px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #F7F7F7;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 113px;
  height: 59px;
  &.reset{
    font-weight: 400;
    color: #C0C0C0;
    padding: 20px 36px;
  }
  &.backspace{
    padding: 17px 44px;
    img{
      width: 25px;
      height: 25px;
    }
  }
  @media (max-width:780px){
    padding: 13px 34px;
    width: 78px;
    height: 45px;
    &.reset{
      padding: 15px 21px 14px 21px;
      font-size: 0.875rem;
      line-height: 1.026rem;
    }
    &.backspace{
      padding: 13px 29px 12px 29px;
    }
    img{
      width: 20px;
      height: 20px;
    }
  }
`

const ModalBtn = styled.div`
  margin-top: 20px;
  background: ${({ disabled }) => (disabled ? '#D9D9D9' : 'linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%)')};
  border-radius: 10px;
  font-weight: 700;
  color: ${({ disabled }) => (disabled ? '#AAAAAA' : '#fff')};
  font-size: 1rem;
  line-height: 1.5rem;
  height: 56px; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media (max-width:780px){
    margin-top: 15px;
    height: 50px;
  }
`

const ModalPw = ({ 
  isOpen,  // 모달 오픈
  closeModal, // 모달 클로즈
  modalOtp, // 모달에 표시되는 값 저장
  setModalOtp, // 모달에 표시되는 값 변경
  otpModalBtn, // 6자리 모두 입력
  handleReset, // 값 reset
  handleBackspace, // 값 한개 삭제
  zIndex, 
  title = "거래 비밀번호 입력" ,
  showBtn = false, // 버튼 표시 여부
  modalType
}) => {

  // 버튼 비활성화 상태
  const [isBtnDisabled, setIsBtnDisabled] = useState(true); 

   // 모달이 열릴 때 스크롤 비활성화
   useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'auto'; 
    }
  }, [isOpen]);

  useEffect(() => {
    setIsBtnDisabled(modalOtp.length !== 6);
  }, [modalOtp]);

  if (!isOpen) return null;

  const handleNumberClick = (number) => {
    if (modalOtp.length < 6) {
      const newOtp = modalOtp + number;
      setModalOtp(newOtp);

      if (newOtp.length === 6 && !showBtn) {
        otpModalBtn(newOtp);
        closeModal();
      }
    }
  };

  // 확인 버튼 클릭 
  const handleConfirmClick = () => {
    if (modalOtp.length === 6) {
      otpModalBtn(modalOtp);
      closeModal(); 
    }
  };

  return (
    <>
      <ModalOverlay onClick={closeModal} style={{ zIndex }} />
      <ModalContainer style={{zIndex}}>
        <CloseBtn onClick={closeModal}>
          <img src='./../img/common_close_w_btn.png' alt="Close" />
        </CloseBtn>
        <ModalTitle>{title}</ModalTitle>
        <ModalWrap>
          <ModalContentTitle>코드를 입력하세요</ModalContentTitle>
          <ModalOtpContainer>
            <ModalOtpNumberWrap>
              {[0, 1, 2].map((index) => (
                <ModalOtpNumber $modalType={modalType} key={index} className={`${modalOtp[index] ? 'active' : ''}`}>
                  {modalOtp[index]}
                </ModalOtpNumber>
              ))}
            </ModalOtpNumberWrap>
            <ModalOtpNumberWrap>
              {[3, 4, 5].map((index) => (
                <ModalOtpNumber $modalType={modalType} key={index} className={`${modalOtp[index] ? 'active' : ''}`}>
                  {modalOtp[index]}
                </ModalOtpNumber>
              ))}
            </ModalOtpNumberWrap>
          </ModalOtpContainer>

          <ModalNumberWrap>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <ModalNumber key={num} onClick={() => handleNumberClick(num.toString())}>
                {num}
              </ModalNumber>
            ))}
            <ModalNumber onClick={handleReset} className='reset'>Reset</ModalNumber>
            <ModalNumber onClick={() => handleNumberClick('0')}>0</ModalNumber>
            <ModalNumber onClick={handleBackspace} className='backspace'>
              <img src='./../img/backspace.png' alt="Backspace" />
            </ModalNumber>
          </ModalNumberWrap>
        </ModalWrap>
        {
          showBtn &&
          <ModalBtn onClick={handleConfirmClick} disabled={isBtnDisabled}>확인</ModalBtn>
        }
      </ModalContainer>
    </>
  );
};

export default ModalPw;
