import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTER_PATHS } from './constants/routerPaths';

const ProtectedRoute = ({ element, ...rest }) => {
    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!token || !refreshToken) {
        return <Navigate to={ROUTER_PATHS.LOGIN} />;
    }

    return element;
};

export default ProtectedRoute;