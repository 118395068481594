import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../utils/apiThunk";
import REDUX_STATUS from "../constants/reduxStatus";
import API_URL from "../constants/apiUrl";
import log from 'loglevel';

export const swapListApi = createApiThunk('swap/list', API_URL.SWAP_LIST, 'get');
export const swapScreenApi = createApiThunk('swap/screen', API_URL.SWAP_SCREEN, 'get');
export const swapRequestApi = createApiThunk('swap/request', API_URL.SWAP_REQUEST, 'post');

const swapSlice = createSlice({
    name: 'swap',
    initialState: {
        status: 'idle',
        error: null,
        data: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            // swap list

            .addCase(swapListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(swapListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(swapListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })

            // swap screen

            .addCase(swapScreenApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(swapScreenApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(swapScreenApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })

            // swap request

            .addCase(swapRequestApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(swapRequestApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(swapRequestApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});

export default swapSlice.reducer;
