import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { useNavigate } from 'react-router-dom';
import ProfitChart from '../components/ProfitChart';
import { useDispatch, useSelector } from 'react-redux';
import { profitListApi } from '../features/profit/profitSlice';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import { getUtcTimeYmd } from '../utils/utils';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentWrap = styled.div`
    width: 100%;
`

const ContentTitle = styled.div`
    padding: 40px 0px 16px 0px;
    border-bottom: 2px solid #666666;
    display: flex;
    align-items: center;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        padding-right: 6px;
    }
    span{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.318rem;
        color: #A9A9A9;
    }
    @media (max-width:780px){
        padding: 30px 0px 10px 0px;
    }
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  thead tr{
    padding: 15px;
  }
  thead tr th{
    height: 51px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.318rem;
    color: #000;
  }
  tbody tr td{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.172rem;
    color: #222222;
    height: 40px;
    min-width: 150px;
    cursor: default;
  }
  tr{
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  th,td{
    text-align: center;
  }
`

const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  color: #666666;
  padding: 80px 0px;
  @media (max-width:780px){
    padding: 50px 0px;
  }
`

function ProfitList() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // month 내역
  const [monthList, setMonthList] = useState();
  // month sum 내역
  const [monthSum, setMonthSum] = useState();
  // confirm 내역
  const [confirmList, setConfirmList] = useState();
  // confirm sum 내역
  const [confirmSum, setConfirmSum] = useState();
  // pending 내역
  const [pendingList, setPendingList] = useState();
  // pending sum 내역
  const [pendingSum, setPendingSum] = useState();
  // cancel 내역
  const [cancelList, setCancelList] = useState();
  // cancel sum 내역
  const [cancelSum, setCancelSum] = useState();
  // 차트 값
  const [chartNum, setChartNum] = useState(0);

  const { data, status, error } = useSelector((state) => state.profit);

  const handleClick = (day, mode, sendYn) => {
    navigate('/profitDetailList', { state: { day, mode, sendYn } })
  };

  const fetchData = async () => {
    try {
      const result = await dispatch(profitListApi());

      setMonthList(result.payload.profitList.profitMonth);
      setMonthSum(result.payload.profitList.profitMonth.monthSum);
      setConfirmList(result.payload.profitList.profitWeekY);
      setConfirmSum(result.payload.profitList.profitWeekY.weekYSum);
      setPendingList(result.payload.profitList.profitWeekN);
      setPendingSum(result.payload.profitList.profitWeekN.weekNSum);
      setCancelList(result.payload.cancelList);
      setCancelSum(result.payload.cancelList.cancelTotalAmount);

      const nowAmount = result.payload?.stakingRequestEntity?.nowAmount ?? 0;
      const amount = result.payload?.stakingRequestEntity?.amount ?? 1; 
      setChartNum(Math.floor((nowAmount / amount) * 100)); 

    } catch (error) {
      console.log("수익 내역 불러오는 중 오류 발생", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>수익내역</Title>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <div style={{ maxWidth: '300px', height: '150px', position: 'relative' }}>
              <ProfitChart chartNum={chartNum} />
            </div>
          </div>
          <ContentWrap>
            <ContentTitle>
              <p>Month 내역</p>
            </ContentTitle>
            <TableContainer>
              {
                monthList?.month.length === 0 ?
                  <TableNoData>내역이 없습니다.</TableNoData>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>일시</th>
                        <th>staking</th>
                        {/* <th>소개</th>
                        <th>직급</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        monthList?.month.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.month}</td>
                              <td>{item.mode.staking}</td>
                              {/* <td>{item.mode.recommend}</td>
                              <td>{item.mode.position}</td> */}
                            </tr>
                          )
                        })
                      }
                      <tr>
                        <td>SUM</td>
                        <td>{monthSum?.staking || 0}</td>
                        {/* <td>{monthSum?.recommend || 0}</td>
                        <td>{monthSum?.position || 0}</td> */}
                      </tr>
                    </tbody>
                  </Table>
              }
            </TableContainer>
            <ContentTitle>
              <p>Confirm 내역</p>
            </ContentTitle>
            <TableContainer>
              {
                confirmList?.weekY.length === 0 ?
                  <TableNoData>내역이 없습니다.</TableNoData>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>일시</th>
                        <th>staking</th>
                        {/* <th>소개</th>
                        <th>직급</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        confirmList?.weekY.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.day}</td>
                              <td style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.day, "staking", "Y") }}>{item.modes.staking}</td>
                              {/* <td style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.day, "recommend", "Y") }}>{item.modes.recommend}</td>
                              <td style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.day, "position", "Y") }}>{item.modes.position}</td> */}
                            </tr>
                          )
                        })
                      }
                      <tr>
                        <td>SUM</td>
                        <td>{confirmSum?.staking || 0}</td>
                        {/* <td>{confirmSum?.recommend || 0}</td>
                        <td>{confirmSum?.position || 0}</td> */}
                      </tr>
                    </tbody>
                  </Table>
              }
            </TableContainer>
            <ContentTitle>
              <p>Pending 내역</p>
            </ContentTitle>
            <TableContainer>
              {
                pendingList?.weekN.length === 0 ?
                  <TableNoData>내역이 없습니다.</TableNoData>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>일시</th>
                        <th>staking</th>
                        {/* <th>소개</th>
                        <th>직급</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pendingList?.weekN.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.day}</td>
                              <td style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.day, "staking", "N") }}>{item.modes.staking}</td>
                              {/* <td style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.day, "recommend", "N") }}>{item.modes.recommend}</td>
                              <td style={{ cursor: 'pointer' }} onClick={() => { handleClick(item.day, "position", "N") }}>{item.modes.position}</td> */}
                            </tr>
                          )
                        })
                      }
                      <tr>
                        <td>SUM</td>
                        <td>{pendingSum?.staking || 0}</td>
                        {/* <td>{pendingSum?.recommend || 0}</td>
                        <td>{pendingSum?.position || 0}</td> */}
                      </tr>
                    </tbody>
                  </Table>
              }
            </TableContainer>
            <ContentTitle>
              <p>Cancel 내역</p>
            </ContentTitle>
            <TableContainer>
              {
                cancelList?.list.length === 0 ?
                  <TableNoData>취소 내역이 없습니다.</TableNoData>
                  :
                  <Table>
                    <thead>
                      <tr>
                        <th>일시</th>
                        <th>staking</th>
                        {/* <th>소개</th>
                        <th>직급</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        cancelList?.list.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.stakingCancelDate === null ? "-" : getUtcTimeYmd(item.stakingCancelDate) || ''}</td>
                              <td>{item.amount}</td>
                              {/* <td>0</td>
                              <td>0</td> */}
                            </tr>
                          )
                        })
                      }
                      <tr>
                        <td>SUM</td>
                        <td>{cancelSum || 0}</td>
                        {/* <td>0</td>
                        <td>0</td> */}
                      </tr>
                    </tbody>
                  </Table>
              }
            </TableContainer>
          </ContentWrap>
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default ProfitList