import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getQrCodeApi, myInfoApi, postOtpApi, userInfoApi, resetState } from '../features/myInfoSlice';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import { handleCopy } from '../utils/utils';

const LayoutBg = styled.div`
    background-color: #FAFFF1;
    height: 100%;
    padding-top: 100px;
    width: 100%;
    @media (max-width:780px){
        padding-top: 40px;
    }
`

const Container = styled.div`
    max-width: 1290px;
    margin: 0 auto;
    padding: 110px 20px 120px 20px;
    h3{
        font-weight: 700;
        font-size: 2.125rem;
        line-height: 2.49rem;
        text-align: center;
    }
    @media (max-width:780px){
        padding: 60px 20px 50px 20px;
        h3{
            font-size: 1.25rem;
            line-height: 1.465rem;
        }
    }
`

const ContentWrap = styled.div`
    width: 100%;
`

const DeleteTxt = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    cursor: pointer;
    margin: 8px 9px 0px 0px;
    @media (max-width:780px){
        display: none;
    }
    span {
        color: #AAAAAA;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
        letter-spacing: -0.64px;
    }
    img {
        width: 16px;
        height: 16px;
    }
`

const DeleteTxtM = styled.div`
   display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    cursor: pointer;
    margin-top: 16px;
    @media (min-width:781px){
        display: none;
    }
    span {
        color: #AAAAAA;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
        letter-spacing: -0.64px;
    }
    img {
        width: 16px;
        height: 16px;
    }
`

const ContentTitle = styled.div`
    padding: 40px 0px 16px 0px;
    border-bottom: 2px solid #666666;
    display: flex;
    align-items: center;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        padding-right: 6px;
    }
    span{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.318rem;
        color: #A9A9A9;
    }
    @media (max-width:780px){
        padding: 30px 0px 10px 0px;
    }
`

const Content = styled.div`
    border-bottom: 1px solid #D9D9D9;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    @media (max-width:780px){
        flex-wrap: wrap;
        padding: 12px 5px;
    }
`

const ContentBtnBox = styled.div`
    display: flex;
    width: 100%;
`

const ContentTxt = styled.p`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.172rem;
    padding: 13px 0px;
    max-width: 223px;
    width: 100%;
    color: #222222;
    @media (max-width:780px){
        padding: 0px 0px 8px 0px;
    }
`

const ContentInputWrap = styled.div`
    position: relative;
    max-width: 460px;
    width: 100%;
    img{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
    @media (max-width:780px){
        max-width: 100%;
    }
`

const ContentInput = styled.input`
    max-width: 460px;
    width: 100%;
    height: 45px;
    border: 1px solid #C4FF69;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #FFFFFFD9;
    color: #1F1F1F;
    &.first{
        background-color: #D8D8D8D9;
        color: #6F6F6F;
        &:focus{
            outline: none;
        }
    }
    &:focus:not(.first):not(.txt){
        outline: none;
        border-color: #16BFB6;
        background: #fff;
    }
    &::placeholder{
        font-size: 1rem;
        line-height: 1.5rem;
        color: #6F6F6F;
    }
    &.txt{
        font-size: 1rem;
        line-height: 1.5rem;
        background-color: #D8D8D8D9;
        color: #6F6F6F;
        &:focus{
            outline: none;
        }
    }
    &.googleOtp {
        cursor: default;
    }
    @media (max-width:780px){
        max-width: 100%;
        padding: 5px 10px;
        &::placeholder{
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: #6F6F6F;
            letter-spacing: -0.24px;
        }
    }
`

const ContentBtn = styled.button`
    min-width: ${(props)=>props.$register ? '90px' : '74px'};
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #16BFB6;
    color: #fff;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-left: 15px;
    font-family: "NotoSansKR";
    @media (max-width:780px){
        min-width: 43px;
        margin-left: 8px;
        padding: 5px 0px;
        font-size: 0.75rem;
        line-height: 1.5rem;
    }
`

const ModifyBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // 회원탈퇴 넣으면 margint-top : 10px 로 변경
    /* margin-top: 10px; */
    margin-top: 50px;
    @media (max-width:780px){
        margin-top: 20px;
    }
`

const ModifyBtn = styled.button`
    font-family: 'Roboto';
    max-width: 460px;
    width: 100%;
    padding: 16px 0;
    background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    @media (max-width:780px){
        max-width: 100%;
    }
`

const ModalOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000A6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 640px;
    transform: translateX(-50%) translateY(-50%);
    background-color: #222222;
    border-radius: 10px;
    padding: 48px 30px 40px 30px;
    z-index: 51;
    &.delete {
        padding: 48px 30px 30px 30px;
    }
    @media (max-width:780px){
        width: 320px;
        padding: 35px 20px 30px 20px;
    }
`

const ClostBtn = styled.div`
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 30px;
    @media (max-width:780px){
        top: 15px;
        right: 12px;
    }
`

const ModalTitle = styled.div`
    font-weight: 700;
    color: #fff;
    font-size: 1.75rem;
    line-height: 2.051rem;
    text-align: center;
    @media (max-width:780px){
        font-size: 1.5rem;
        line-height: 1.758rem;
    }
`

const ModalWrap = styled.div`
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 30px 25px 30px;
    margin-top: 20px;
    &.delete{
        margin-top: 21px;
        text-align: center;
        @media (max-width:780px){
            margin-top: 12px;
            height: auto;
        }
    }
    @media (max-width:780px){
        overflow-y: auto;
        height: 500px;
        margin-top: 12px;
        padding: 20px 15px 20px 15px;
    }
`

const ModalWrapTxt = styled.p`
    color: #666666;
    font-weight: 400;
    text-align: center;
    font-size: 1rem;
    line-height: 1.313rem;
    @media (max-width:780px){
        font-size: 0.875rem;
        line-height: 1.125rem;
        letter-spacing: -0.84px;
    }
`

const ModalBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    gap: 10px;
    @media (max-width:780px){
        margin-top: 15px;
        gap: 6px;
    }
`

const ModalBtn = styled.div`
    width: 255px;
    height: 56px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    color: #AAAAAA;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.64px;
    &.delete{
        color: #FFFFFF;
        background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
    }
`

const ModalContentTitle = styled.div`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-bottom: 10px;
    border-bottom: 2px solid #666666;
    @media (max-width:780px){
        padding-bottom: 3px;   
        font-size: 1rem;
        line-height: 1.5rem;
    }
`

const ModalContent = styled.div`
    padding: 13px 0px 36px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    img{
        cursor: pointer;
        height: 80px;
        &.down_apple{
            width: 240px;
        }
        &.down_google{
            width: 270px;
        }
    }
    @media (max-width:780px){
        padding: 8px 0px 16px 0px;
        justify-content: center;
        gap: 4px;
        img{
            height: 37px;
            &.down_apple{
                width: 111px;
            }
            &.down_google{
                width: 125px;
            }
        }
    }
`

const ModalOtpWrap = styled.div`
    padding: 13px 0px 36px 0px;
    display: flex;
    align-items: center;
    gap: 15px;
    @media (max-width:780px){
        padding: 8px 0px 16px 0px;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }
    > img{
        width: 148px;
        height: 148px;
        @media (max-width:780px){
            width: 80px;
            height: 80px;
        }
    }
`

const ModalOtpTxt = styled.div`
    display: flex;
    flex-direction: column; 
    padding-right: 40px;
    @media (max-width:780px){
        padding-right: 0;
        flex-direction: column-reverse;
        align-items: center;
    }
    p{
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        @media (max-width:780px){
            font-size:0.813rem;
            line-height: 1.188rem;
            letter-spacing: -0.5px;
        }
    }
`

const ModalOtpBox = styled.div`
    max-width: 210px;
    width: 100%;
    padding: 5px 15px;
    background-color: #F8F8F8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    p{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #AAAAAA;
        @media (max-width:780px){
            font-size: 0.75rem;
        }
    }
    img{
        cursor: pointer;
        width: 24px;
        height: 24px;
        @media (max-width: 780px){
            width: 20px;
            height: 20px;
        }
    }
    @media (max-width:780px){
        max-width: 164px;
        margin: 8px 0px 11px 0px;
        padding: 4px 10px;
    }
`

const ModalOtpCopy = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #16BFB6;
    border-radius: 4px;
    @media (max-width:780px){
        width: 20px;
        height: 20px;
    }
    img{
        width: 14px;
        height: 14px;
        @media (max-width:780px){
            width: 11.67px;
            height: 11.67px;
        }
    }
`

const ModalAuthWrap = styled(Content)`
    padding: 8px 0px;
    justify-content: space-between;
    &.border_bottom_none{
        @media (max-width:780px){
            border-bottom: none;
            padding-bottom: 0;
        }
    }
`

const ModalAuthTxt = styled.p`
    font-weight: 700;
    font-size: 1rem;
    padding-left: 3px;
    @media (max-width:780px){
        padding-bottom: 8px;
    }
`

const ModalAuthInputWrap = styled(ContentInputWrap)`
    max-width: 398px;
`

const ModalAuthInput = styled(ContentInput)`
    max-width: 398px;
    border: 1px solid #D9D9D9;
    @media (max-width:780px){
        height: 35px;
    }
`

const ModalAuthBtnWrap = styled(ModifyBtnWrap)`
    margin-top: 25px;
    @media (max-width:780px){
        margin-top: 10px;
    }
`

const ModalAuthBtn = styled(ModifyBtn)`
    max-width: 520px;
    @media (max-width:780px){
        padding: 13px 0px;
    }
`

function MyInfo() {
    const dispatch = useDispatch();
    const { status, error, data } = useSelector((state) => state.myinfo);
    const { otpKey, otpUrl } = useSelector(state => state.myinfo.otpData);
    const userPwRef = useRef(null);
    const changePwRef = useRef(null);
    const changePwCheckRef = useRef(null);
    const tradePwRef = useRef(null);
    const changeTradePwRef = useRef(null);
    const changeTradePwCheckRef = useRef(null);
    const otpNumberRef = useRef(null);
    const otpTradeRef = useRef(null);
    const walletRef = useRef(null);
    const nickNameRef = useRef(null);

    const [eye, setEye] = useState(Array(8).fill(0));
    const [isOtp,setIsOtp] = useState(false);

    // deleteAccount
    const [isDelete, setIsDelete] = useState(false);

    const [otpNumber, setOtpNumber] = useState('');
    const [tradePw, setTradePw] = useState('');

    const [userInfo , setUserInfo] = useState({
        userNickName : "",
        walletAddress : "",
        userPw : "",
        changePw : "",
        changePwCheck : "",
        tradePw : "",
        changeTradePw : "",
        changeTradePwCheck : ""
    });

    const focusField = (index) => {
        const refs = [
            userPwRef,
            changePwRef,
            changePwCheckRef,
            tradePwRef,
            changeTradePwRef,
            changeTradePwCheckRef,
            otpNumberRef,
            otpTradeRef,
            walletRef,
            nickNameRef
        ];
        refs[index].current.focus();
    };

    // 비밀번호 유효성 검사 함수
       const isValidPassword = (password) => {
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d@$!%*?&\^{}[\]:;<>,.?\/#~_+\-=|`'"\\(){}[\]]{10,20}$/.test(password);
    };

    // 거래 비밀번호 유효성 검사 함수
    const isValidTradePassword = (password) => {
        return /^\d{6}$/.test(password);
    };

    // 닉네임 유효성 검사 함수
    const isValidNickName = (nickName) => {
        // 공백 제외 문자 두개 이상 , 특수문자x , 공백 허용 / 한글 / 영어 대소문자만 허용
        return /^(?!\s*$)(?=(.*[^\s]){2,})[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9\s]{2,20}$/.test(nickName);
    };

    const toggleEye = (index) =>{
        const newEye = [...eye];
        newEye[index] = !newEye[index];
        setEye(newEye);
    };

    const resetEyeState = () => {
        const newEye = [...eye];
        newEye[6] = false;
        newEye[7] = false;
        setEye(newEye);
    };

    const resetEye = () => {
        const newEye = [...eye];
        newEye[0] = false;
        newEye[1] = false;
        newEye[2] = false;
        newEye[3] = false;
        newEye[4] = false;
        newEye[5] = false;
        setEye(newEye);
    };

    // 상태 초기화 함수
    const resetUserInfo = () => {
        setUserInfo({
            userPw: '',
            changePw: '',
            changePwCheck: '',
            tradePw: '',
            changeTradePw: '',
            changeTradePwCheck: '',
            userNickName : ''
        });
    };
    
    const handleCloseModal = () => {
        setIsOtp(false);
        resetEyeState();
    };

    // Qr코드 
    const handleGetQrCode = async () => {
        try {
            await dispatch(getQrCodeApi());
            setIsOtp(true);
        } catch (error) {
            console.log('QR 코드 가져오기 중 오류 발생', error);
        }
    };
    
    // 구글 OTP 등록
    const handlePostOtp = async () => {

        if(otpNumber === ""){
            alert("구글 OTP 번호를 입력해 주세요.");
            focusField(6);
            return;
        }
        if(tradePw === ""){
            alert("거래 비밀번호를 입력해 주세요");
            focusField(7);
            return;
        }
        if(!isValidTradePassword(tradePw)){
            alert('거래 비밀번호는 6자리 숫자로 입력해 주세요.');
            focusField(7);
            return;
        }

        try{
            const response = await dispatch(postOtpApi({
                otpSecretKey: otpKey,
                otpNumber,
                tradePw
            }));

            if(response.type === 'myinfo/postOtp/fulfilled'){
                alert("정상적으로 구글 OTP 등록하였습니다.")
                handleCloseModal();
                dispatch(userInfoApi());
            }

        }catch(error){
            console.error('OTP 등록 중 오류 발생', error);
        }

    };

    // 수정하기 버튼
    const handleModify = async () => {
        let isValid = true;
        let modifyData = {}; 
      
        if(userInfo.userNickName === "" || data?.userNickName !== null){
            // 로그인 비밀번호와 거래 비밀번호 둘 다 입력되지 않은 경우
            if (
                userInfo.userPw === "" &&
                userInfo.changePw === "" &&
                userInfo.changePwCheck === "" &&
                userInfo.tradePw === "" &&
                userInfo.changeTradePw === "" &&
                userInfo.changeTradePwCheck === ""
            ) {
                alert("로그인 비밀번호나 거래 비밀번호 둘 중 하나는 입력하셔야 합니다.");
                return;
            }
        }

        // 이름 변경 유효성 검사
        if (userInfo.userNickName !== "") {
            if(!isValidNickName(userInfo.userNickName)){
                alert("이름은 2~20자 한/영소문자, 숫자를 사용하세요.");
                focusField(9);
                isValid = false;
                return;
            }
            modifyData.userNickName = userInfo.userNickName.trim();
        }
      
        // 로그인 비밀번호 변경 유효성 검사
        if (userInfo.userPw !== "" || userInfo.changePw !== "" || userInfo.changePwCheck !== "") {
            // if (!isValidPassword(userInfo.userPw)) {
            //     alert('비밀번호는 10 ~ 20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.');
            //     focusField(0);
            //     isValid = false;
            //     return;
            // }
            if(userInfo.userPw === ""){
                alert('기존 비밀번호를 입력해 주세요.');
                focusField(0);
                isValid = false;
                return;
            }
        
            if (userInfo.changePw === "") {
                alert("새 비밀번호를 입력해 주세요.");
                focusField(1);
                isValid = false;
                return;
            }
            if (!isValidPassword(userInfo.changePw)) {
                alert('비밀번호는 10 ~ 20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.');
                focusField(1);
                isValid = false;
                return;
            }
            if (userInfo.changePwCheck === "") {
                alert("새 비밀번호 확인을 입력해 주세요.");
                focusField(2);
                isValid = false;
                return;
            }
            if (userInfo.changePw !== userInfo.changePwCheck) {
                alert("새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.");
                focusField(2);
                isValid = false;
                return;
            }
        
            modifyData.userPw = userInfo.userPw;
            modifyData.changePw = userInfo.changePw;
            modifyData.changePwCheck = userInfo.changePwCheck;
        }
        
        // 거래 비밀번호 변경 유효성 검사
        if (userInfo.tradePw !== "" || userInfo.changeTradePw !== "" || userInfo.changeTradePwCheck !== "") {
            if (!isValidTradePassword(userInfo.tradePw)) {
                alert('거래 비밀번호는 6자리 숫자로 입력해 주세요.');
                focusField(3);
                isValid = false;
                return;
            }
            if (userInfo.changeTradePw === "") {
                alert("새 거래 비밀번호를 입력해 주세요.");
                focusField(4);
                isValid = false;
                return;
            }
            if (!isValidTradePassword(userInfo.changeTradePw)) {
                alert('거래 비밀번호는 6자리 숫자로 입력해 주세요.');
                focusField(4);
                isValid = false;
                return;
            }
            if (userInfo.changeTradePwCheck === "") {
                alert("새 거래 비밀번호 확인을 입력해 주세요.");
                focusField(5);
                isValid = false;
                return;
            }
            if (userInfo.changeTradePw !== userInfo.changeTradePwCheck) {
                alert("새 거래 비밀번호와 새 거래 비밀번호 확인이 일치하지 않습니다.");
                focusField(5);
                isValid = false;
                return;
            }
        
            modifyData.tradePw = userInfo.tradePw;
            modifyData.changeTradePw = userInfo.changeTradePw;
            modifyData.changeTradePwCheck = userInfo.changeTradePwCheck;
        }
      
        if (isValid) {
            try {
                const response = await dispatch(myInfoApi(modifyData));
            
                if (response.type === 'myinfo/modify/fulfilled') {
                    alert("수정이 완료되었습니다.");
                    resetUserInfo();
                    dispatch(userInfoApi());
                    resetEye();
                }
            } catch (error) {
                console.log("내 정보 수정 중 오류 발생", error);
            }
        }
    };

    // 수정하기 엔터키
    const activeEnter = (e) => {
        if(e.key === "Enter") {
            handleModify();
        }
    };

    const activeOtpEnter = (e) => {
        if(e.key === "Enter") {
            handlePostOtp();
        }
    };

    // 구글 otapApp 다운로드 핸들러
    const handleDownOtpApp = (appType) => {
        
        let downloadUrl = '';
    
        // 애플기종이 구글 누르면 새창으로 구글 스토어 다운로드로 이동
        if (appType === 'google') {
            downloadUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
        } else if (appType === 'app') {
            downloadUrl = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
        }

        window.open(downloadUrl, '_blank');
    };

    // 회원 탈퇴 
    const deleteAccount = () => {
        // console.log("회원탈퇴 클릭");
    };

    useEffect(() => {
        document.body.style.overflow = isOtp || isDelete ? 'hidden' : 'auto';
    }, [isOtp , isDelete]);

    useEffect(() => {

        dispatch(userInfoApi());

        return () => {
            dispatch(resetState());
        };

    }, [dispatch]);

    return (
        <>
        {status === STATUSES.LOADING && <Loading />}
            {
                isOtp &&
                <>
                    <ModalOverlay onClick={()=>{setIsOtp(false); resetEyeState();}} />
                    <ModalContainer>
                        <ClostBtn onClick={handleCloseModal}>
                            <img src='./../img/common_close_w_btn.png' />
                        </ClostBtn>
                        <ModalTitle>Security</ModalTitle>
                        <ModalWrap>
                            <ModalContentTitle>Google OTP APP 다운로드</ModalContentTitle>
                            <ModalContent>
                                <img className='down_apple' onClick={() => handleDownOtpApp('app')} src='./../img/modal_down_app.png' />
                                <img className='down_google' onClick={() => handleDownOtpApp('google')} src='./../img/modal_down_google.png' />
                            </ModalContent>
                            <ModalContentTitle>계정연결</ModalContentTitle>
                            <ModalOtpWrap>
                                <img src={otpUrl} />
                                <ModalOtpTxt>
                                    <div style={{width:'100%'}}>
                                        <p>위 QR코드를 스캔하거나 비밀 키를 입력하여</p>
                                        <p>Google OTP를 GMMTSTAKING 계정에 연결하세요.</p>
                                    </div>
                                    <ModalOtpBox>
                                        <p>{otpKey}</p>
                                        <ModalOtpCopy onClick={() => { handleCopy(otpKey) }}>
                                            <img src='./../img/common_copy_ico.png' alt='Copy OTP Key' />
                                        </ModalOtpCopy>
                                    </ModalOtpBox>
                                </ModalOtpTxt>
                            </ModalOtpWrap>
                            <ModalContentTitle>거래인증</ModalContentTitle>
                            <ModalAuthWrap>
                                <ModalAuthTxt>구글 OTP 번호</ModalAuthTxt>
                                <ModalAuthInputWrap>
                                    <ModalAuthInput
                                        ref={otpNumberRef}
                                        onKeyDown={(e) => activeOtpEnter(e)}
                                        type={eye[6] ? 'password' : 'text'}
                                        onChange={(e) => setOtpNumber(e.target.value)} />
                                    <img src={eye[6] ? './../img/common_viewoff_ico@3x.png' : './../img/common_view_ico@3x.png'} onClick={()=>{toggleEye(6)}}/>
                                </ModalAuthInputWrap>
                            </ModalAuthWrap>
                            <ModalAuthWrap className='border_bottom_none'>
                                <ModalAuthTxt>거래 비밀번호</ModalAuthTxt>
                                <ModalAuthInputWrap>
                                    <ModalAuthInput
                                        ref={otpTradeRef}
                                        onKeyDown={(e) => activeOtpEnter(e)}
                                        type={eye[7] ? 'text' : 'password'}
                                        onChange={(e) => setTradePw(e.target.value)} />
                                    <img src={eye[7] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(7)}}/>
                                </ModalAuthInputWrap>
                            </ModalAuthWrap>
                            <ModalAuthBtnWrap>
                                <ModalAuthBtn onClick={handlePostOtp}>등록하기</ModalAuthBtn>
                            </ModalAuthBtnWrap>
                        </ModalWrap>
                    </ModalContainer>
                </>
            }
            {
                isDelete &&
                <>
                    <ModalOverlay onClick={()=>{setIsDelete(false);}} />
                    <ModalContainer className='delete'>
                        <ClostBtn onClick={()=>{setIsDelete(false);}}>
                            <img src='./../img/common_close_w_btn.png' />
                        </ClostBtn>
                        <ModalTitle>회원탈퇴</ModalTitle>
                        <ModalWrap className='delete'>
                            <ModalWrapTxt>탈퇴 후 기존 계정 정보는 복구할 수 없습니다.</ModalWrapTxt>
                            <ModalWrapTxt>그래도 탈퇴하시겠습니까?</ModalWrapTxt>
                            <ModalBtnWrap>
                                <ModalBtn className='delete' onClick={()=>{setIsDelete(false)}}>취소</ModalBtn>
                                <ModalBtn onClick={deleteAccount}>탈퇴하기</ModalBtn>
                            </ModalBtnWrap>
                        </ModalWrap>
                    </ModalContainer>
                </> 
            }
            <Header />
            <LayoutBg>
                <Container>
                    <h3>내정보</h3>
                    <ContentWrap>
                        <ContentTitle>
                            <p>회원 정보</p>
                        </ContentTitle>
                        <Content>
                            <ContentTxt>아이디</ContentTxt>
                            <ContentInput className='first' value={data?.userId || ''} readOnly />
                        </Content>
                        <Content>
                            <ContentTxt>이름</ContentTxt>
                            <ContentInput
                                ref={nickNameRef}
                                className={data?.userNickName ? 'first' : ''}
                                placeholder="이름 입력 (2~20자 한/영소문자, 숫자)"
                                maxLength={20}
                                readOnly={!!data?.userNickName}
                                value={data?.userNickName ? data?.userNickName : userInfo.userNickName || ''}
                                onChange={(e) => setUserInfo({ ...userInfo, userNickName: e.target.value })}
                            />
                        </Content>
                        <ContentTitle>
                            <p>보안설정</p>
                            <span>(Google Authenticatior)</span>
                        </ContentTitle>
                        <Content>
                            <ContentTxt>구글 OTP</ContentTxt>
                            <ContentBtnBox>
                                <ContentInput className="googleOtp" placeholder={data?.otpSecretKey ? data?.otpSecretKey : '미등록'} readOnly />
                                {
                                    !data?.otpSecretKey &&
                                    <ContentBtn $register={data?.otpSecretKey} onClick={handleGetQrCode}>등록</ContentBtn>
                                }
                            </ContentBtnBox>
                        </Content>
                        <ContentTitle>
                            <p>로그인 비밀번호 변경</p>
                        </ContentTitle>
                        <Content>
                            <ContentTxt>기존 비밀번호</ContentTxt>
                            <ContentInputWrap>
                                <ContentInput 
                                    ref={userPwRef}
                                    onKeyDown={(e) => activeEnter(e)}
                                    value={userInfo.userPw || ''}
                                    onChange={(e) => setUserInfo({ ...userInfo, userPw: e.target.value })}
                                    placeholder='기존 비밀번호 입력' type={eye[0] ? 'text' : 'password'} />
                                <img src={eye[0] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(0)}}/>
                            </ContentInputWrap>
                        </Content>
                        <Content>
                            <ContentTxt>새 비밀번호</ContentTxt>
                            <ContentInputWrap>
                                <ContentInput 
                                    ref={changePwRef}
                                    onKeyDown={(e) => activeEnter(e)}
                                    value={userInfo.changePw || ''}
                                    onChange={(e) => setUserInfo({ ...userInfo, changePw: e.target.value })}
                                    placeholder='비밀번호 입력 (영문, 숫자, 특수문자 포함 10자리 이상)' type={eye[1] ? 'text' : 'password'} />
                                <img src={eye[1] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(1)}}/>
                            </ContentInputWrap>
                        </Content>
                        <Content>
                            <ContentTxt>새 비밀번호 확인</ContentTxt>
                            <ContentInputWrap>
                                <ContentInput 
                                    ref={changePwCheckRef}
                                    onKeyDown={(e) => activeEnter(e)}
                                    value={userInfo.changePwCheck || ''}
                                    onChange={(e) => setUserInfo({ ...userInfo, changePwCheck: e.target.value })}
                                    placeholder='새 비밀번호 한번 더 입력' type={eye[2] ? 'text' : 'password'} />
                                <img src={eye[2] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(2)}}/>
                            </ContentInputWrap>
                        </Content>
                        <ContentTitle>
                            <p>거래 비밀번호 변경</p>
                        </ContentTitle>
                        <Content>
                            <ContentTxt>기존 거래 비밀번호</ContentTxt>
                            <ContentInputWrap>
                                <ContentInput
                                    ref={tradePwRef}
                                    value={userInfo.tradePw || ''}
                                    onKeyDown={(e) => activeEnter(e)}
                                    onChange={(e) => setUserInfo({ ...userInfo, tradePw: e.target.value })}
                                    placeholder='기존 거래 비밀번호 입력' type={eye[3] ? 'text' : 'password'} />
                                <img src={eye[3] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(3)}}/>
                            </ContentInputWrap>
                        </Content>
                        <Content>
                            <ContentTxt>새 거래 비밀번호</ContentTxt>
                            <ContentInputWrap>
                                <ContentInput
                                    ref={changeTradePwRef}
                                    value={userInfo.changeTradePw || ''}
                                    onKeyDown={(e) => activeEnter(e)}
                                    onChange={(e) => setUserInfo({ ...userInfo, changeTradePw: e.target.value })}
                                    placeholder='새 거래 비밀번호 입력 (숫자 6자리)' type={eye[4] ? 'text' : 'password'} />
                                <img src={eye[4] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(4)}}/>
                            </ContentInputWrap>
                        </Content>
                        <Content>
                            <ContentTxt>새 거래 비밀번호 확인</ContentTxt>
                            <ContentInputWrap>
                                <ContentInput
                                    ref={changeTradePwCheckRef}
                                    value={userInfo.changeTradePwCheck || ''}
                                    onKeyDown={(e) => activeEnter(e)}
                                    onChange={(e) => setUserInfo({ ...userInfo, changeTradePwCheck: e.target.value })}
                                    placeholder='새 거래 비밀번호 한번 더 입력' type={eye[5] ? 'text' : 'password'} />
                                <img src={eye[5] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={()=>{toggleEye(5)}}/>
                            </ContentInputWrap>
                        </Content>
                    </ContentWrap>
                    {/* <DeleteTxt onClick={()=>{setIsDelete(true)}}>
                        <span>회원탈퇴</span>
                        <img src='./../img/common_arrow_ico_gr.png' />
                    </DeleteTxt> */}
                    <ModifyBtnWrap>
                        <ModifyBtn onClick={handleModify}>
                            수정하기
                        </ModifyBtn>
                    </ModifyBtnWrap>
                    {/* <DeleteTxtM onClick={()=>{setIsDelete(true)}}>
                        <span>회원탈퇴</span>
                        <img src='./../img/common_arrow_ico_gr.png' />
                    </DeleteTxtM> */}
                </Container>
                <Footer />
            </LayoutBg>
        </>
    )
}

export default MyInfo