import React, { useEffect, useRef } from "react";

const defaultSymbols = [
    {
        description : "GATEIO",
        proName: "GATEIO:GMMTUSDT"
    },
    {
        description : "MEXC",
        proName: "MEXC:GMMTUSDT"
    },
    // {
    //     description : "BITGET",
    //     proName: "BITGET:GMMTUSDT"
    // },
    {
        description : "BITMART",
        proName: "BITMART:GMMTUSDT"
    },
    {
        description : "BITFINEX",
        proName:  "BITFINEX:GMMTUSD"
    },
    {
        description : "ETH",
        proName:  "BINANCE:ETHUSD"
    },
    {
        description : "BTC",
        proName:  "BINANCE:BTCUSD"
    },
];

const Ticker = ({
        showSymbolLogo = true,
        isTransparent = true,
        displayMode = "compact",
        colorTheme = "light",
        locale = "en",
        symbols = defaultSymbols,
    }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = JSON.stringify({
            showSymbolLogo,
            isTransparent,
            displayMode,
            colorTheme,
            locale,
            symbols,
        });
        if (containerRef.current) {
            containerRef.current.appendChild(script);
        }

        return () => {
            if (containerRef.current) {

                containerRef.current.innerHTML = "";
            }
        };
    }, [showSymbolLogo, isTransparent, displayMode, colorTheme, locale, symbols]);

    return (
        <div className="tradingview-widget-container" ref={containerRef}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default Ticker;