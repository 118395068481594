import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profitDetailListApi } from '../features/profit/profitSlice';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentWrap = styled.div`
    width: 100%;
`

const ContentTitle = styled.div`
    padding: 40px 0px 16px 0px;
    border-bottom: 2px solid #666666;
    display: flex;
    align-items: center;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        padding-right: 6px;
    }
    span{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.318rem;
        color: #A9A9A9;
    }
    @media (max-width:780px){
        padding: 30px 0px 10px 0px;
    }
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  thead tr{
    padding: 15px;
  }
  thead tr th{
    height: 51px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.318rem;
    color: #000;
  }
  tbody tr td{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.172rem;
    color: #222222;
    height: 51px;
    min-width: 150px;
  }
  tr{
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  th,td{
    text-align: center;
  }
`

const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  color: #666666;
  padding: 150px 0px;
  @media (max-width:780px){
    padding: 180px 0px;
  }
`

function ProfitDetailList() {

  const location = useLocation();
  const dispatch = useDispatch();
  const { day, mode, sendYn } = location.state;
  const [detailList, setDetailList] = useState();
  const { status, error, data } = useSelector((state) => state.profit);
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    
    return `${year}년 ${month}월`;
  };

  const fetchData = async () => {
    try {
      const result = await dispatch(profitDetailListApi({ day: day, mode: mode, sendYn: sendYn }));
      setDetailList(result.payload);

    } catch (e) {
      console.log("수익 상세 내역 불러오는중 오류 발생", e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>수익 상세 내역</Title>
          <ContentWrap>
            <ContentTitle>
              <p>{sendYn === 'Y' ? 'Confirm' : 'Pending'} {mode === 'staking' ? 'staking' : mode === 'recommend' ? '소개' : '직급'} 상세 내역</p>
            </ContentTitle>
            <TableContainer>
              {
                detailList?.length === 0 ?
                  <TableNoData>상세 내역이 없습니다.</TableNoData>
                  :
                  mode === "position" ?
                    <Table>
                      <thead>
                        <tr>
                          <th>Payment Date</th>
                          <th>Month Date</th>
                          <th>Rewards</th>
                          <th>Month Bonus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          detailList?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.day}</td>
                                <td>{formatDate(item.day)}</td>
                                <td>{item.benefitAmount}</td>
                                <td>{item.benefitNumber} 회차</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    :
                    <Table>
                      <thead>
                        <tr>
                          <th>Week Date</th>
                          <th>Referral ID</th>
                          <th>Rewards</th>
                          <th>Week Bonus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          detailList?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.day}</td>
                                <td>{mode === 'recommend' ? item.parentId : item.userId}</td>
                                <td>{item.benefitAmount}</td>
                                <td>{item.benefitNumber} 회차</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
              }
            </TableContainer>
          </ContentWrap>
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default ProfitDetailList