import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Pagination from '../components/pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { stakingCancelApi, stakingRequestListApi, stakingPrincipalReceiveApi } from '../features/staking/stakingSlice';
import { addCommasToNumber, getUtcTimeYmd, getUtcTimeYmdHms } from '../utils/utils';
import { useDeviceDetect } from '../components/utils/utils';
import STATUSES from '../constants/reduxStatus';
import Loading from '../components/loading/Loading';
import { useNavigate } from 'react-router-dom';
import ModalPw from '../components/modal/ModalPw';
import { dashBoardApi } from '../features/dashBoardSlice';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  padding-bottom: 16px;
  border-bottom: 2px solid #666666;
  @media (max-width:780px){
    padding-bottom: 10px;
  }
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  thead tr{
    padding: 15px;
  }
  thead tr th{
    height: 51px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.318rem;
    color: #000;
  }
  tbody tr td{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    height: 51px;
  }
  tr{
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  th,td{
    text-align: center;
    &.profit {
      color: #FF0E0E;
    }
  }
  @media (max-width:880px){
    display: none;
  }
`

const TableState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableWalletAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const TableTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

// 대기중 wrap
const StatePendingWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

// 대기중
const StatePending = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #A9A9A9;
`

// 대기중 옆에 취소버튼
const StatePendingCancel = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #FF0E0E;
  color: #fff;
  border-radius: 15px;
  padding: 5px 12px;
  height: 29px;
  cursor: pointer;
  @media (max-width:880px) {
    padding: 3px 12px;
    height: 25px;
  }
`

// 원금 받기 버튼
const StateReceive = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #16BFB6;
  color: #fff;
  border-radius: 15px;
  padding: 5px 12px;
  height: 29px;
  cursor: pointer;
  @media (max-width:880px) {
    padding: 3px 12px;
    height: 25px;
  }
`

// 진행중
const StateProgress = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #000;
`

// 졸업
const StateComplete = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #16BFB6;
`

// 실패
const StateFail = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #FF0E0E;
`

const MtableWrap = styled.div`
  padding: 10px 5px 0px 5px;
  @media (min-width:881px){
    display: none;
  }
`

const MtableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.total {
    justify-content: flex-end;
  }
`

const MtableBottom = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid #D9D9D9;
`

const MtableDate = styled.div`
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  color: #222222;
`

const MtablTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  color: #222222;
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  span {
    &.profit {
      color: #FF0E0E;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
  p{
    font-weight: 700;
  }
`

const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  color: #666666;
  padding: 150px 0px;
  @media (max-width:880px){
    padding: 180px 0px;
  }
`

function StakingList() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useDeviceDetect(880);
  const { status, error, data } = useSelector((state) => state.staking);
  // 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 한 페이지에 보여줄 게시글 수
  const pageSize = 10;
  // 모바일 스테이킹 내역 배열
  const [detailList, setDetailList] = useState([]);
  // 취소시 거래 비밀번호 모달창 오픈
  const [isPwModal, setIsPwModal] = useState(false);
  // 원금 받기 클릭시 거래 비밀번호 모달창 오픈
  const [receiveModal, setReceiveModal] = useState(false);
  // 모달창안에서 거래 비밀번호 
  const [modalPw, setModalPw] = useState("");
  // 스테이킹 아이디 저장
  const [stakingId, setStakingId] = useState(null);

  const fetchData = async (page) => {
    try {
      setPageNum(page);
      const result = await dispatch(stakingRequestListApi({ currentPage: pageNum, pageSize: pageSize }));
      const content = result.payload.content;

      if (page === 1) {
        setDetailList(content);
      } else {
        setDetailList(prevList => [...prevList, ...content]);
      }

    } catch (error) {
      console.log("스테이킹 내역 불러오는 중 오류 발생", error)
    }
  };

  // 대기시 취소 클릭
  const handleCancelClick = (id) => {
    setStakingId(id);
    setIsPwModal(true);
    setModalPw("");
  };

  // 원금 받기 클릭
  const handleReceiveClick = (id) => {
    setStakingId(id);
    setReceiveModal(true);
    setModalPw("");
  };

  // 거래 비밀번호 backspace
  const handlePwBackspace = () => {
    setModalPw(modalPw.slice(0, -1));
  };

  // 거래 비밀번호 reset
  const handleReset = () => {
    setModalPw('');
  };

  // 취소 클릭시 거래 비밀번호 모달 설정 버튼
  const handleSubmit = async () => {

    try {

      //   const response = ({
      //    stakingId: stakingId, // number
      //    tradePw: modalPw//string
      //  });

      //  console.table(response);

      const response = await dispatch(stakingCancelApi({
        stakingId: stakingId, // number
        tradePw: modalPw //string
      }));

      if (response.type === 'staking/requestCancel/fulfilled') {
        alert("스테이킹 취소가 완료되었습니다.");
        navigate('/dashBoard');
      }

    } catch (error) {
      console.log("스테이킹 취소 중 오류 발생", error);
    }
  };

  // 원금 받기 클릭시 거래 비밀번호 모달 설정 버튼
  const handlePrincipalReceive = async () => {

    try {

      // const response = ({
      //   stakingId: stakingId, // number 
      //   tradePw: modalPw //string
      // });

      // console.table(response);

      const response = await dispatch(stakingPrincipalReceiveApi({
        stakingId: stakingId, // number
        tradePw: modalPw //string
      }));

      if (response.type === 'staking/principalReceive/fulfilled') {
        alert("원금 받기가 완료되었습니다.");
        navigate('/stakingList');
      }

    } catch (error) {
      console.log("원금 받기 중 오류 발생", error);
    }

  };

  useEffect(() => {
    fetchData(pageNum);
  }, [dispatch, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [isMobile]);

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>staking 신청내역</Title>
          <ContentTitle>신청 내역</ContentTitle>
          <TableContainer>
            {
              status === STATUSES.SUCCEEDED &&
                data?.content?.length === 0 ?
                <TableNoData>신청 내역이 없습니다.</TableNoData>
                :
                <Table>
                  <thead>
                    <tr>
                      <th>NO</th>
                      <th>신청일</th>
                      <th>승인일</th>
                      <th>신청 수량</th>
                      <th>스테이킹 기간</th>
                      <th>연 수익률</th>
                      <th>주차</th>
                      <th>총 이익</th>
                      <th>Action</th>
                      <th>End date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data?.content && data?.content?.length > 0 &&
                      data?.content?.map((item, index) => {
                        const postNumber = data?.totalElements - (pageNum - 1) * pageSize - index;
                        return (
                          <tr key={index}>
                            <td style={{ minWidth: '60px' }}>{postNumber || ''}</td>
                            <td style={{ minWidth: '170px' }}>{getUtcTimeYmdHms(item.stakingRequest.createdAt) || ''}</td>
                            <td style={{ minWidth: '170px' }}>{item.stakingRequest.approvalDate === null ? "-" : getUtcTimeYmdHms(item.stakingRequest.approvalDate) || ''}</td>
                            <td style={{ minWidth: '130px' }}>
                              <TableTrxId>
                                <p>{addCommasToNumber((item.stakingRequest.amount ?? 0).toFixed(0))}</p>
                              </TableTrxId>
                            </td>
                            <td style={{ minWidth: '130px' }}>{item.stakingRequest.stakingPeriod || '0'}일</td>
                            <td style={{ minWidth: '100px' }}>{item.stakingRequest.stakingInterest || '0'}%</td>
                            <td style={{ minWidth: '100px' }}>{item.benefitLogCount || '0'}주</td>
                            <td style={{ minWidth: '100px' }}>{addCommasToNumber((item.stakingRequest.nowAmount ?? 0).toFixed(4) === '0.0000' ? '0' : (item.stakingRequest.nowAmount ?? 0).toFixed(4))}</td>
                            <td style={{ minWidth: '100px' }}>
                              <TableState>
                                {
                                  item.stakingRequest.flag === 1 ?
                                    <StatePendingWrap>
                                      <StatePending>대기</StatePending>
                                    </StatePendingWrap>
                                    :
                                    item.stakingRequest.flag === 2 ?
                                      <StatePendingWrap>
                                        <StateProgress>진행중</StateProgress>
                                        {
                                          item.isCancel === true &&
                                          <StatePendingCancel onClick={() => { handleCancelClick(item.stakingRequest.id) }}>취소</StatePendingCancel>
                                        }
                                      </StatePendingWrap>
                                      :
                                      item.stakingRequest.flag === 3 ?
                                      <div>
                                        {
                                          item.stakingRequest.principalReciveYn === "N" ?
                                          <StateReceive onClick={()=>{handleReceiveClick(item.stakingRequest.id)}}>원금 받기</StateReceive>
                                          :
                                          <StateComplete>완료</StateComplete>
                                        }
                                      </div>
                                      :
                                      item.stakingRequest.flag === 5 &&
                                      <StateFail>실패</StateFail>
                                }
                              </TableState>
                            </td>
                            <td style={{ minWidth: '100px' }}>{item.stakingRequest.endDate === null ? "-" : getUtcTimeYmd(item.stakingRequest.endDate) || ''}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
            }
            {
              receiveModal &&
              <ModalPw
                  isOpen={receiveModal}
                  closeModal={() => setReceiveModal(false)}
                  modalOtp={modalPw}
                  otpModalBtn={handlePrincipalReceive}
                  setModalOtp={setModalPw}
                  handleReset={handleReset}
                  handleBackspace={handlePwBackspace}
                  showBtn={true}
              />
            }
            {
              isPwModal &&
              <ModalPw
                isOpen={isPwModal}
                closeModal={() => setIsPwModal(false)}
                modalOtp={modalPw}
                otpModalBtn={handleSubmit}
                setModalOtp={setModalPw}
                handleReset={handleReset}
                handleBackspace={handlePwBackspace}
                showBtn={true}
              />
            }
            {/* 모바일 출금 내역 */}
            {
              isMobile &&
              detailList?.map((item, index) => {
                return (
                  <MtableWrap key={index}>
                    <MtableTop>
                      <MtableDate>{getUtcTimeYmdHms(item.stakingRequest.createdAt) || ''}</MtableDate>
                      {
                        item.stakingRequest.flag === 1 ?
                          <StatePendingWrap>
                            <StatePending>대기</StatePending>
                            <StatePendingCancel onClick={() => { handleCancelClick(item.stakingRequest.id) }}>취소</StatePendingCancel>
                          </StatePendingWrap>
                          :
                          item.stakingRequest.flag === 2 ?
                            <StatePendingWrap>
                              <StateProgress>진행중</StateProgress>
                              {
                                item.isCancel === true &&
                                <StatePendingCancel onClick={() => { handleCancelClick(item.stakingRequest.id) }}>취소</StatePendingCancel>
                              }
                            </StatePendingWrap>
                            :
                            item.stakingRequest.flag === 3 ?
                              <div>
                                {
                                  item.stakingRequest.principalReciveYn === "N" ?
                                  <StateReceive onClick={()=>{handleReceiveClick(item.stakingRequest.id)}}>원금 받기</StateReceive>
                                  :
                                  <StateComplete>완료</StateComplete>
                                }
                              </div>
                              :
                              item.stakingRequest.flag === 5 &&
                              <StateFail>실패</StateFail>
                      }
                    </MtableTop>
                    <MtableBottom>
                      <MtablTrxId>
                        <p>승인일</p>
                        <span>{item.stakingRequest.approvalDate === null ? "-" : getUtcTimeYmdHms(item.stakingRequest.approvalDate) || ''}</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>신청수량</p>
                        <span>{addCommasToNumber((item.stakingRequest.amount ?? 0).toFixed(0))}</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>스테이킹 기간</p>
                        <span>{item.stakingRequest.stakingPeriod || '0'}일</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>연 수익률</p>
                        <span>{item.stakingRequest.stakingInterest || '0'}%</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>총이익</p>
                        <span>{addCommasToNumber((item.stakingRequest.nowAmount ?? 0).toFixed(4))}</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>종료날짜</p>
                        <span>{item.stakingRequest.endDate === null ? "-" : getUtcTimeYmd(item.stakingRequest.endDate) || ''}</span>
                      </MtablTrxId>
                    </MtableBottom>
                  </MtableWrap>
                )
              })
            }
          </TableContainer>
          <Pagination
            onChange={(num) => setPageNum(num)}
            data={data?.content && data}
            putPageNum={pageNum}
          />
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default StakingList