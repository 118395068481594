import React from 'react';
import styled from 'styled-components';

const ModalNumberWrap = styled.div`
  width: 355px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
  @media (max-width:780px){
    width: 250px;
    row-gap: 10px;
  }
`

const ModalNumber = styled.div`
  padding: 20px 51px 20px 52px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #F7F7F7;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 113px;
  height: 59px;
  &.reset{
    font-weight: 400;
    color: #C0C0C0;
    padding: 20px 36px;
  }
  &.backspace{
    padding: 17px 44px;
    img{
      width: 25px;
      height: 25px;
    }
  }
  @media (max-width:780px){
    padding: 13px 34px;
    width: 78px;
    height: 45px;
    &.reset{
      padding: 15px 21px 14px 21px;
      font-size: 0.875rem;
      line-height: 1.026rem;
    }
    &.backspace{
      padding: 13px 29px 12px 29px;
    }
    img{
      width: 20px;
      height: 20px;
    }
  }
`

const NumberPad = ({ handleNumberClick, handleBackspace, reset }) => {
  return (
    <ModalNumberWrap>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
        <ModalNumber key={num} onClick={() => handleNumberClick(num.toString())}>
          {num}
        </ModalNumber>
      ))}
      <ModalNumber onClick={reset} className='reset'>Reset</ModalNumber>
      <ModalNumber onClick={() => handleNumberClick('0')}>0</ModalNumber>
      <ModalNumber onClick={handleBackspace} className='backspace'>
        <img src='./../img/backspace.png' alt="Backspace" />
      </ModalNumber>
    </ModalNumberWrap>
  );
};

export default NumberPad;
