import { createSlice } from '@reduxjs/toolkit';

import log from 'loglevel';
import API_URL from '../../constants/apiUrl';
import REDUX_STATUS from '../../constants/reduxStatus';
import { createApiThunk } from '../../utils/apiThunk';

export const profitListApi = createApiThunk('profit/list', API_URL.PROFIT_LIST, 'get');
export const profitDetailListApi = createApiThunk('profit/detail', API_URL.PROFIT_DETAIL_LIST, 'get');

const profitSlice = createSlice({

    name: 'profit',
    initialState: {
        status: 'idle',
        error: null,
        data: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(profitListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(profitListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(profitListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(profitDetailListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(profitDetailListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(profitDetailListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            });
    },
});

export default profitSlice.reducer;
