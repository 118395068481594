import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import RadioBtn from '../components/input/RadioBtn';
import ModalCnt from '../components/modal/ModalCnt';
import NumberPad from '../components/modal/NumberPad';
import ModalPw from '../components/modal/ModalPw';
import { useDispatch, useSelector } from 'react-redux';
import { dashBoardApi } from '../features/dashBoardSlice';
import { addCommasToNumber } from '../utils/utils';
import { stakingRequestApi } from '../features/staking/stakingSlice';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import { swapRequestApi, swapScreenApi } from '../features/swapSlice';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentWrap = styled.div`
    width: 100%;
`

const ContentTitle = styled.div`
    padding: 40px 0px 16px 0px;
    border-bottom: 2px solid #666666;
    display: flex;
    align-items: center;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        padding-right: 6px;
    }
    span{
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.318rem;
        color: #A9A9A9;
    }
    @media (max-width:780px){
        padding: 30px 0px 10px 0px;
    }
`

const ContentInputWrap = styled.div`
    position: relative;
    max-width: 442px;
    width: 100%;
    img{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
    @media (max-width:780px){
        max-width: 100%;
    }
`

const ContentInput = styled.input`
    max-width: 460px;
    width: 100%;
    height: 45px;
    border: 1px solid #C4FF69;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #FFFFFFD9;
    color: #1F1F1F;
    cursor: pointer;
    &.first{
        background-color: #D8D8D8D9;
        color: #6F6F6F;
        &:focus{
            outline: none;
        }
    }
    &:focus:not(.first):not(.txt){
        outline: none;
        border-color: #16BFB6;
        background: #fff;
    }
    &::placeholder{
        font-size: 1rem;
        line-height: 1.5rem;
        color: #6F6F6F;
    }
    &.txt{
        font-size: 1rem;
        line-height: 1.5rem;
        background-color: #D8D8D8D9;
        color: #6F6F6F;
        &:focus{
            outline: none;
        }
    }
    &.googleOtp {
        cursor: default;
    }
    @media (max-width:780px){
        max-width: 100%;
        padding: 5px 10px;
        height: 35px;
        &::placeholder{
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: #6F6F6F;
            letter-spacing: -0.24px;
        }
    }
`

const ContentBtn = styled.button`
   min-width: 107px;
  background-color: #16BFB6;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: "NotoSansKR";
  font-weight: 700;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  cursor: pointer;
  margin-left: 15px;
  @media (max-width:780px){
    min-width: 65px;
    font-size: 0.75rem;
    padding: 5px 10px;
    margin-left: 8px;
  }
`

const ModifyBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // 회원탈퇴 넣으면 margint-top : 10px 로 변경
    /* margin-top: 10px; */
    margin-top: 50px;
    @media (max-width:780px){
      margin-top: 20px;
    }
`

const ModifyBtn = styled.button`
    font-family: 'Roboto';
    max-width: 460px;
    width: 100%;
    padding: 16px 0;
    background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    @media (max-width:780px){
      max-width: 100%;
      padding: 13px 0;
    }
`

const ContentRow = styled.div`
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  @media (max-width:780px){
    flex-wrap: wrap;
    padding: 12px 5px;
    &.application_cnt{
      padding: 12px 5px 9px 5px;
    }
  }
`

const ContentRowTitle = styled.div`
  padding: 25px 15px;
  min-width: 210px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  @media (max-width:780px){
    padding: 0px 0px 8px 0px;
    width: 100%;
    &.application_cnt{
      padding: 0px 0px 6px 0px;
    }
  }
`

const ContentRowTxt = styled.div`
  width: 100%; 
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  word-break: break-all;
  @media (max-width:780px){
    font-size: 0.875rem;
    line-height: 1.026rem;
    letter-spacing: -0.42px;
  }
`

const ContentInputContainer = styled.div`
  width: 100%;
  display: flex;
`

const ContentInputTxt = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
  p{
    font-weight: 400;
    color: #6F6F6F;
  }
  span{
    min-width: 40px;
    font-weight: 700;
    text-align: right;
  }
  @media (max-width:780px){
    right: 10px; 
    gap: 6px; 
    font-size: 0.75rem;
    span{
      min-width: 30px;
    }
  }
`

const CheckWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  p {
    cursor: pointer;
    color: #222;
  }
  @media (max-width:780px) {
    p {
      font-size: 0.875rem;
    }
  }
`

const GmmtCnt = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    font-size: 1.125rem;
  }
  @media (max-width:780px) {
    p {
      font-size: 1rem;
    }
  }
`

const ModalContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  text-align: center;
`

const ModalContentCnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.5rem;
  line-height: 1.758rem;
  font-weight: 700;
  padding: 28px 0px 41px 0px;
  p{
    color: #16BFB6;
  }
  @media (max-width:780px){
    padding: 19px 0px 40px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ModalWrap = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
  }

  &.apply {
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    padding: 20px;
  }
  @media (max-width:780px){ 
    margin-top: 12px;
    padding: 25px 15px 15px 15px;
  }
`

const ModalBtn = styled.div`
  margin-top: 20px;
  background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
  border-radius: 10px;
  font-weight: 700;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 355px;
  height: 56px; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.delete{
      color: #AAAAAA;
      background: none;
      background-color: #D9D9D9;
  }
  @media (max-width:780px){
    margin-top: 15px;
    width: 250px;
    height: 50px;
  }
`

function SwapRequest() {

  const dispatch = useDispatch();
  // gmmt 보유수량 조회
  const { status, error, data } = useSelector((state) => state.swap);
  // 거래 비밀번호 눈 아이콘
  const [eye, setEye] = useState(false);
  // 신청 수량 모달창 오픈
  const [isCntModal, setIsCntModal] = useState(false);
  // 거래 비밀번호 모달창 오픈
  const [isPwModal, setIsPwModal] = useState(false);
  // 계약 신청 모달창 오픈
  const [modalApply, setModalApply] = useState(false);
  // 모달창안에서 신청 수량
  const [modalCnt, setModalCnt] = useState('0');
  // input 신청 수량
  const [cnt, setCnt] = useState('0');
  // 모달창안에서 거래 비밀번호 
  const [modalPw, setModalPw] = useState("");
  // input 거래 비밀번호
  const [tradePw, setTradePw] = useState("");

  // 신청수량 모달 숫자클릭
  const handleNumberClick = (number) => {
    setModalCnt((prev) => {
      const newCnt = prev === '0' ? number : prev + number;
      const newCntValue = Number(newCnt);
  
      if (newCntValue >= 100000000) { 
        alert("최대 수량을 초과할 수 없습니다.");
        return '0'; 
      }
  
      return newCnt; 
    });
  };

  // 거래 비밀번호 모달 오픈
  const openPwModal = () => {
    setIsPwModal(true);
    setModalPw("");
    setTradePw("");
  };

  // 신청수량 모달 뒤로가기
  const handleBackspace = () => {
    setModalCnt((prev) => prev.length > 1 ? prev.slice(0, -1) : '0');
  };

  // 거래 비밀번호 backspace
  const handlePwBackspace = () => {
    setModalPw(modalPw.slice(0, -1));
  };

  // 거래 비밀번호 reset
  const handleReset = () => {
    setModalPw('');
  };

  // 신청 수량 모달 설정 버튼
  const cntModalBtn = () => {
    if(data?.nowPoint < parseInt(modalCnt)){
      alert("보유수량을 초과할 수 없습니다.");
      setModalCnt('0');
      return;
    }
    setIsCntModal(false);
    setCnt(modalCnt);
  };

  // 신청수량 모달창 오픈 (신청수량 input 클릭)
  const handleCntInput = () => {
    setIsCntModal(true);
    setModalCnt('0');
    setCnt('0');
    setTradePw("");
  };

  // 거래 비밀번호 마지막 번호 클릭
  const pwLastClick = (newOtp) => {
    setIsPwModal(false);
    setTradePw(newOtp);
  };

  // 신청 최대수량 클릭시 소수점 버리고 정수
  const maxCnt = () => {
    setCnt(Math.floor(data?.nowPoint).toString());
  };

  // 스테이킹 신청하기 버튼
  const handleSubmit = async () => {

    if(cnt === '0'){
      alert('신청 수량을 입력해 주시길 바랍니다.');
      return;
    }

    if(tradePw === ""){
      alert("거래 비밀번호를 입력해 주세요.")
      return;
    }

    try {
      const response = await dispatch(swapRequestApi({
        amount : parseInt(cnt), // number
        tradePw : tradePw, // string
      }));

      if(response.type === 'swap/request/fulfilled') {
        alert("Swap 신청이 완료되었습니다.");
        window.location.reload();
      } else if (response.type === 'swap/request/rejected'){
        alert(response.payload.message)
      }

    } catch(error) {
      console.error('Swap 신청하기 중 오류 발생', error)
    }
  };

  useEffect(() => {
    dispatch(swapScreenApi());
  }, [dispatch])

  // 모달창 오픈시 스크롤 x
  useEffect(() => {
    document.body.style.overflow = isCntModal || isPwModal || modalApply ? 'hidden' : 'auto';
  }, [isCntModal, isPwModal, modalApply]);

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>Swap 신청</Title>
          <ContentWrap>
            <ContentTitle>
              <p>Swap 신청</p>
            </ContentTitle>
            <ContentRow>
              <ContentRowTitle>Swap 가능 GMMT</ContentRowTitle>
              <ContentRowTxt>
                <GmmtCnt>
                  <p>{addCommasToNumber(data?.nowPoint ?? 0)}<span> GMMT</span></p>
                </GmmtCnt>
              </ContentRowTxt>
            </ContentRow>
            <ContentRow className='application_cnt'>
              <ContentRowTitle className='application_cnt'>신청 수량</ContentRowTitle>
              <ContentInputContainer>
                <ContentInputWrap onClick={handleCntInput}>
                  <ContentInput readOnly />
                  <ContentInputTxt>
                    <p>{cnt}</p>
                    <span>GMMT</span>
                  </ContentInputTxt>
                </ContentInputWrap>
                <ContentBtn onClick={maxCnt}>최대수량</ContentBtn>
              </ContentInputContainer>
            </ContentRow>
            {
              isCntModal &&
              <ModalCnt isOpen={isCntModal} closeModal={() => setIsCntModal(false)} title="수량 설정">
                <ModalWrap>
                  <ModalContentTitle>수량 입력</ModalContentTitle>
                  <ModalContentCnt>
                    <p>{addCommasToNumber(modalCnt)}</p>
                    <span>GMMT</span>
                  </ModalContentCnt>
                  <NumberPad
                    handleNumberClick={handleNumberClick}
                    handleBackspace={handleBackspace}
                    reset={() => setModalCnt('0')}
                  />
                  <ModalBtn onClick={cntModalBtn}>설정</ModalBtn>
                </ModalWrap>
              </ModalCnt>
            }
            <ContentRow>
              <ContentRowTitle>수수료</ContentRowTitle>
              <ContentRowTxt>
                {(() => {
                  const fee = ((data?.swapFee / 100) * cnt);
                  return fee === 0 ? fee : fee.toFixed(4)
                })()} GMMT
                </ContentRowTxt>
            </ContentRow>
            <ContentRow>
              <ContentRowTitle>Swap GMMT</ContentRowTitle>
              <ContentRowTxt>{(() => {
                const swapGmmt = (cnt - (data?.swapFee / 100) * cnt)
                return swapGmmt === 0 ? swapGmmt : swapGmmt.toFixed(4)
              })()} GMMT</ContentRowTxt>
            </ContentRow>
            
            <ContentTitle>
              <p>인증</p>
            </ContentTitle>
            <ContentRow>
              <ContentRowTitle>거래 비밀번호</ContentRowTitle>
              <ContentInputWrap>
                <ContentInput
                  readOnly
                  onClick={openPwModal}
                  value={tradePw}
                  placeholder='거래 비밀번호 입력' type={eye ? 'text' : 'password'}
                />
                <img src={eye ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={() => { setEye(!eye) }} />
              </ContentInputWrap>
            </ContentRow>
            {
             isPwModal &&
             <ModalPw
              isOpen={isPwModal}
              closeModal={() => setIsPwModal(false)}
              modalOtp={modalPw}
              setModalOtp={setModalPw}
              otpModalBtn={pwLastClick}
              handleReset={handleReset}
              handleBackspace={handlePwBackspace}
            />
            }
          </ContentWrap>

          <ModifyBtnWrap>
            <ModifyBtn onClick={handleSubmit}>신청하기</ModifyBtn>
          </ModifyBtnWrap>
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default SwapRequest