import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dashBoardApi } from '../features/dashBoardSlice';
import { addCommasToNumber, formatBalance } from '../utils/utils';

const LayoutBg = styled.div`
    background: url('/img/bgimg.png') no-repeat center;
    background-size: cover;
    min-height: 100svh;
    width: 100%;  
    padding: 100px 20px 0px;
    position: relative;
    @media (max-width:780px){
        padding: 60px 20px 0px;
    }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
    min-height: calc(100svh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:780px) {
        min-height: calc(100svh - 140px);
    }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding-bottom: 100px;
  text-align: center;
  @media (max-width:780px){
    font-size: 1.25rem;
    line-height: 1.465rem;
    padding-bottom: 50px;
  }
`

const CardWrap = styled.div`
    display: flex;  
    align-items: center;
    gap: 40px;
    width: 100%;
    @media (max-width:780px){
        flex-wrap: wrap;
        gap: 20px;
    }
`

const CardContainer = styled.div`
    background-color: #FFFFFFB2;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    flex-basis: 50%;
    padding: 45px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 280px;
    gap: 20px;
    &:first-child {
        cursor: pointer;
    }
    @media (max-width:780px){
        gap: 30px;
        min-height: auto;
        flex-basis: 100%;
        padding: 25px 20px;
    }
`

const CardLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media (max-width:780px) {
        gap: 15px;
    }
`

const CardLeftTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    @media (max-width:780px) {
        font-size: 0.875rem;
    }
`

const CardIcon = styled.div`
    width: 60px;
    height: 60px;
    img {
        width: 100%;
    }
`

const CardRightTitle = styled.div`
    font-weight: 600;
    font-size: 1.4rem;
    @media (max-width:780px) {
        font-size: 1.25rem;
    }
`

const CardRightCnt = styled.div`
    font-size: 2rem;
    color: #16BFB6;
    font-weight: bold;
    word-break: break-all;
    text-align: right;
    @media (max-width:780px) {
        font-size: 1.5rem;
    }
`

const CardRightTxt = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 780px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
`

function WalletMain() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data, status, error } = useSelector((state) => state.dashboard);

    useEffect(() => {
        dispatch(dashBoardApi());
    }, []);

    return (
    <>
        <Header />
        <LayoutBg>
            <MainLayout>
                <div style={{width:'100%'}}>
                    <Title>Wallet</Title>
                    <CardWrap>
                        <CardContainer onClick={() => {navigate('/credit-list')}}>
                            <CardLeft>
                                <CardIcon>
                                    <img src='./../img/common_gmmt_logo.png' />
                                </CardIcon>
                                <CardLeftTitle>GMMT</CardLeftTitle>
                            </CardLeft>
                            <CardRightTxt>
                                <CardRightTitle>GMMT</CardRightTitle>
                                <CardRightCnt>{addCommasToNumber(formatBalance(data?.gmmtBalance))}</CardRightCnt>
                            </CardRightTxt>
                        </CardContainer>
                        <CardContainer>
                            <CardLeft>
                                <CardIcon>
                                    <img src='./../img/common_etherum_logo.png' />
                                </CardIcon>
                                <CardLeftTitle>ETH</CardLeftTitle>
                            </CardLeft>
                            <CardRightTxt>
                                <CardRightTitle>ETH</CardRightTitle>
                                <CardRightCnt>{addCommasToNumber((data?.eth ?? 0).toFixed(4))}</CardRightCnt>
                            </CardRightTxt>
                        </CardContainer>
                    </CardWrap>
                </div>
            </MainLayout>
            <Footer />
        </LayoutBg>
    </>
  )
}

export default WalletMain