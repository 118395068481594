import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { logout } from '../../features/auth/authSlice'
import { dashBoardApi } from '../../features/dashBoardSlice'
import { readNotificationApi } from '../../features/noticeSlice'
import LanguageSelector from '../select/LanguageSelector'

const NavContainer = styled.div`
    width: 100%;
    height: 100px;
    padding: 0 100px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 10;
    @media (max-width:1476px){
        padding: 0 20px;
    }
    @media (max-width:1355px){
        justify-content: space-between;
    }
    @media (max-width:780px){
        height: 60px;
    }
`

const NavLogo = styled.div`
    cursor: pointer;
    width: 189px;
    /* height: 62px; */
    img{
        width: 100%;
    }
    @media (max-width:1355px){
        width: 120px;
        /* height: 40px; */
    }
`

const NavBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width:1355px){
        display: none;
    }
`

const MnavContainer = styled.div`
    position: relative;
    z-index: 101;
`

const MainMenu = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MainMenuList = styled.li`
    position: relative;
    padding: 5px 15px;
    border-radius: 20px;
    margin-right: 20px;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: ${(props)=> props.$visible ? '100%' : '0'};
        background-color: #16BFB6;
        z-index: -1;
        border-radius: 20px;
        transition: width 0.5s ease-in-out;
    }
    p{
        position: relative;
        z-index: 2;
        font-weight: ${(props)=>props.$entxt ? '600' : '800'};
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        color: ${(props)=> props.$visible ? '#fff' : '#000'};
        transition : color 0.5s;
    }
`

const SubMenu = styled.ul`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #00000033;
    border-radius: 10px;
    padding: 15px 30px;
    margin-top: 6px;
`

const SubMenuList = styled.li`
    padding-bottom: 10px;
    &:last-child{
        padding-bottom: 0;
    }
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    &:hover {
        span{
            color: #000;
            font-weight: 600;
        }
    }
    span{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #4B4B4B;
    }
`


const NavIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 30px;
    img{
        &.cart{
            width: 30px;
            height: 30px;
            cursor: pointer;
            @media (max-width:1355px){
                width: 24px;
                height: 24px;
            }
        }
    }
`

const NotiIcon = styled.div`
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        position: absolute;
        top: 0px;
        right: -2px;
        color: #fff;
        font-size: 0.688rem;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f50d0d;
        z-index: 1;
        @media (max-width:1355px){
            right: 1px;
        }
    }
    img{
        width: 30px;
        height: 30px;
        @media (max-width:1355px){
            width: 24px;
            height: 24px;
        }
    }
`

const NavLanBox = styled.div`
    display: flex;
    align-items: center;
    width: 37px;
    height: 24px;
    @media (max-width:1355px){
        margin-right: 10px;
    }
    p{
        font-weight: 700;
        font-size: 1rem;
        margin-right: 4px;
    }
    img{
        width: 12px;
        height: 9px;
    }
`

const NavIconTextBox = styled.div`
    border-radius: 20px;
    padding: 5px 15px;
    background-color: #EBEBEB;
    cursor: pointer;
    p{
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
        text-align: center;
        color: #AAAAAA;
    }
`

const Hamburger = styled.div`
    cursor: pointer;
    z-index: 11;
    position: relative;
    width: 20px;
    height: 20px;
    > div{
        width: 20px; 
        height: 2px; 
        background-color: #000;
        position: absolute;
        transition: top 0.4s, transform 0.4s;
    }
    div:nth-child(1){top: 0%;}
    div:nth-child(2){top: 50%;}
    div:nth-child(3){top: 100%;}
    &.on div:nth-child(1){
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
    }
    &.on div:nth-child(2){
        opacity: 0;
        transform: translateX(100%);
    }
    &.on div:nth-child(3){
        transform: translateY(-50%) rotate(-45deg);
        top: 50%;
    }
`

const MnavWrap = styled.div`
    max-width: 315px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    transform: ${(props)=>props.$isactive ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.4s; 
    ul{
        li{
            &:first-child{
                border-top: 1px solid #F1F1F1;
            }
            padding: 13px 20px;
            width: 100%;
            border-bottom: 1px solid #F1F1F1;
            font-weight: 800;
            &:nth-of-type(3){
                /* My NFT 만 굵기 다르게 */
                font-weight: 600;
            }
            font-size: 1rem;
            line-height: 1.5rem;
            cursor: pointer;
        }
    }
`

const MnavTop = styled.div`
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    @media (max-width:1355px){
        padding: 30px 20px;
        height: 100px;
    }
    @media (max-width: 780px){
        padding: 10px 20px;
        height: 60px;
    }
`

const MnavLogo = styled.div`
    cursor: pointer;
    img{
        width: 120px;
        /* height: 40px; */
    }
`

const MnavImgWrap = styled.div`
    display: flex;
`

const MnavBox = styled.div`
    position: relative;
    display: none;
    @media (max-width:1355px){
        display: block;
    }
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000A6;
    opacity: ${(props)=>props.$isactive ? '1' : '0'};
    visibility: ${(props) => props.$isactive ? 'visible' : 'hidden'};
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 100;
`

const MnavImgBox = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`

const MnavSubMenuWrap = styled.div`
    padding: ${({ $isopen }) => ($isopen ? '10px 20px' : '0 20px')};
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    background-color: #F8F8F8;
    cursor: pointer;
    max-height: ${({ $isopen }) => ($isopen ? '' : "0")};
    overflow: hidden;
    transition: max-height 0.4s, padding 0.4s;
`

const MnavSubMenuTxt = styled.p`
    color: #4B4B4B;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
`

const MnavIconTxtBox = styled(NavIconTextBox)`
    position: fixed;
    bottom: 30px;
    left: 20px;
`

const MnavQuick = styled.div`
    display: flex;
    align-items: center;
`

const ModalOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000A6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #222222;
    border-radius: 10px;
    padding: 15px 30px 40px;
    z-index: 51;
    min-height: 605px;
    @media (max-width:780px){
        padding: 15px 20px 30px;
        min-height: 580px;
    }
`

const NoticeWrap = styled.div`
    max-height: 465px;
    overflow-y: auto;
    margin-top: 8px;
    @media (max-width:780px){
        margin-top: 11px;
    }
`

const NoNoticeTxt = styled.div`
    min-width: 580px;
    text-align: center;
    padding: 210px 0px 251px 0px;
    color: #AAAAAA;
    @media (max-width:780px){
        min-width: 280px;
        padding: 215px 0px 248px 0px;
    }
`

const GradientOverlay = styled.div`
    content: '';
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(34, 34, 34, 0.4) 41.5%, #222222 83%);
    pointer-events: none;
    @media (max-width:780px){
        bottom: 30px;
    }
`;


const ClostBtn = styled.div`
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    @media (max-width:780px){
        right: 12px;
    }
`

const ModalTitle = styled.div`
    font-weight: 700;
    color: #fff;
    font-size: 1.75rem;
    line-height: 2.051rem;
    text-align: center;
    padding-top: 32px;
    @media (max-width:780px){
        font-size: 1.5rem;
        line-height: 1.758rem;
        padding-top: 20px;
    }
`

const ModalWrap = styled.div`
    width: 580px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    margin-top: 12px;
    @media (max-width:780px){
        margin-top: 10px;
        max-width: 280px;
        padding: 15px 10px;
    }
`

const ModalTitleWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${(props) => (props.$isopen ? 2 : 1)};
        white-space: initial;
        max-width: 476px;
        cursor: ${(props) => (props.$hasUrl ? 'pointer' : 'default')};
        @media (max-width:780px){
            max-width: 232px;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    @media (max-width:780px){
        gap: 8px;
    }
`

const ModalContentWrap = styled.div`
    padding-top: 10px;
    p{
        font-weight: 400;
        color: #AAAAAA;
        font-size: 1rem;
        line-height: 1.5rem;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: ${(props)=>props.$isopen ? 'visible' : 'hidden'};
        white-space: ${(props)=>props.$isopen ? 'normal' : 'nowrap'};
        max-width: 520px;
        @media (max-width:780px){
            font-size: 0.875rem;
            line-height: 1.5rem;
        }
    }
    @media (max-width:780px){
        padding-top: 4px;
    }
`

const ModalCircle = styled.div`
    width: 24px;
    height: 24px;
    background-color: #16BFB6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width:780px){
        width: 20px;
        height: 20px;
    }
`

const NavMenu = [
    { label : "내 정보" , subMenu : ["내 정보", "라인뷰"] },
    { label : "지갑" , subMenu : ["메인", "GMMT"] },
    { label : "Swap" , subMenu : ["Swap 신청", "Swap 내역"] },
    { label : "Staking" , subMenu : ["Staking 신청", "Staking 내역", "수익 내역"] },
    { label : "커뮤니티" , subMenu : ["공지사항"] },
    { label : "Invite Friends" , subMenu : ["Invite Friends"] },
];

const pathMappings = {
    "내 정보": "/myInfo",
    "라인뷰": "/line-tree",
    "Invite Friends" : "/vilage",
    "메인" : "/wallet-main",
    "GMMT" : "/credit-list",
    "Staking 신청" : "/stakingRequest",
    "Staking 내역" : "/stakingList",
    "수익 내역" : "/profitList",
    "Swap 신청": "/swapRequest",
    "Swap 내역": "/swapList", 
    "공지사항" : "/board"
};


function Header() {
    // 모바일 햄버거 메뉴 
    const [isActive,setIsActive] = useState(false);
    // 현재 활성화된 메뉴 인덱스
    const [activeIndex , setActiveIndex] = useState(null);
    // 서브메뉴 
    const [isSubMenuOpen , setIsSubMenuOpen] = useState(false);
    // 알림 모달
    const [isNotice , setIsNotice] = useState(false);
    // 알림 모달의 그라데이션
    const [showGradient, setShowGradient] = useState(false);
    // 알림 각각의 열림 상태
    const [openNotifications, setOpenNotifications] = useState({});
    // 알림 아이디값 배열
    const [unreadNotificationIds, setUnreadNotificationIds] = useState([]);

    const noticeWrapRef = useRef(null);
    const lastElementRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data, status, error } = useSelector((state) => state.dashboard);

    // 로그아웃 
    const handleLogout = () => {
        alert("로그아웃이 완료되었습니다.");
        dispatch(logout());
        navigate('/');
    };

    const navigateToPath = (path) => {
        if (path) {
            navigate(path);
        } else {
            alert("준비 중입니다.");
        }
    };    
    
    // 서브메뉴 클릭시 pathMappings에 해당하는 경로로 이동 , 없다면 준비중
    const handleSubMenuClick = (e,subMenuItem) => {
        e.stopPropagation();
        if (pathMappings) {
            navigateToPath(pathMappings[subMenuItem]);
        } else {
            alert('준비 중입니다.')
        }
        
        // navigateToPath(pathMappings[subMenuItem]);
    };

    // 메인 메뉴 클릭시 첫번째 서브메뉴로 이동
    const handleMainMenuClick = (menuItem) => {
        if (menuItem.subMenu && menuItem.subMenu.length > 0) {
            navigateToPath(pathMappings[menuItem.subMenu[0]]);
        } else {
            alert('준비 중입니다.')
        }
    };

    // 모바일에서 메인메뉴 클릭시 서브메뉴 열고 닫기
    const clickMobileMainMenu = (index) => {
        if (activeIndex === index) {
            setIsSubMenuOpen(false);
            setActiveIndex(null);
        } else {
            setIsSubMenuOpen(true);
            setActiveIndex(index);
        }
    };

    // 마우스 오버시 서브메뉴 열기
    const handleMouseEnter = (index) => {
        setActiveIndex(index);
        setIsSubMenuOpen(true);
    };

    // 서브메뉴 닫기
    const handleMouseLeave = () => {
        setIsSubMenuOpen(false);
        setActiveIndex(null);
    };   

    // 로고 클릭 홈 이동
    const handleLogo = () => {
        navigate('/dashBoard');
    };

    // 알림 모달 열리면 알림의 아이디값을 unreadNotificationIds에 저장
    const handleOpenNoticeModal = () => {

        // alert('준비 중입니다.')

        setIsNotice(true);
      
        if (data?.unreadNotifications && data?.unreadNotifications.length > 0) {
          const unreadIds = data.unreadNotifications.map((notification) => notification.id);
          setUnreadNotificationIds(unreadIds);
        }

    };
      
    // 각각의 알림 클릭
    const handleNotificationClick = (id) => {
        setOpenNotifications((prev) => ({
          ...prev,
          [id]: !prev[id],
        }));
    };

    // 알림 닫기
    const handleClose = async () => {
        setIsNotice(false);

        if (unreadNotificationIds.length > 0) {
            try {
                await dispatch(readNotificationApi({ ids: unreadNotificationIds.join(',') }));
                dispatch(dashBoardApi());
            } catch (error) {
                console.error("error :: ", error);
            }
            setUnreadNotificationIds([]);
        }
    };

    // 제목 클릭 url 이동
    const handleUrl = async (index) => {
        const notification = data.unreadNotifications.find((n) => n.id === index);
        if (notification.url !== null) {
            try {
                await dispatch(readNotificationApi({ ids: unreadNotificationIds.join(',') }));
                window.location.href = notification.url;
            } catch (error) {
                console.error("error :: ", error);
            }
            dispatch(dashBoardApi());
            setUnreadNotificationIds([]);
        }
      };

    // 모바일 메뉴 열렸을때 , 알림 모달 열렸을때 스크롤 제어 
    useEffect(() => {
        if (isActive || isNotice) {
        document.body.style.overflow = 'hidden';
        } else {
        document.body.style.overflow = 'auto';
        }
    }, [isActive, isNotice]);

    // noticeWrapRef 높이 465이상일때 그라데이션
    useEffect(() => {
        if (noticeWrapRef.current) {
            setShowGradient(noticeWrapRef.current.scrollHeight > 465);
        }
    }, [isNotice, data]);

    // ModalWrap 마지막 부분에 도달하면 그라데이션 없어지게 , 다시 스크롤 올리면 그라데이션 나옴
    useEffect(() => {
        const handleObserver = (entries) => {
            entries.forEach(entry => {
                // 요소가 뷰 포트내에 있으면 그라데이션 숨김
                if (entry.isIntersecting) {
                    setShowGradient(false);
                // 그게 아니라면 그라데이션 표시
                } else {
                    setShowGradient(true);
                }
            });
        };

        const observer = new IntersectionObserver(handleObserver, { threshold: 1 });

        if (lastElementRef.current) {
            observer.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.unobserve(lastElementRef.current);
            }
        };
    }, [data,isNotice]);

    return (
        <>
            <NavContainer onMouseLeave={handleMouseLeave}>
                {
                    isNotice &&
                    <>
                        <ModalOverlay onClick={handleClose} />
                        <ModalContainer>
                            <ClostBtn onClick={handleClose}>
                                <img src='./../img/common_close_w_btn.png' />
                            </ClostBtn>
                            <ModalTitle>알림</ModalTitle>
                            {
                                data?.unreadNotifications && data?.unreadNotifications.length > 0 ?
                                <NoticeWrap ref={noticeWrapRef}>
                                    {
                                        data?.unreadNotifications?.map((notification,index)=>{
                                            const isLast = index === data.unreadNotifications.length - 1;
                                            return(
                                                <ModalWrap key={index} ref={isLast ? lastElementRef : null}>
                                                    <ModalTitleWrap $isopen={openNotifications[notification.id]} $hasUrl={notification.url !== null}>
                                                        <p onClick={()=>{handleUrl(notification.id)}}>{notification.title}</p>
                                                        <ModalCircle onClick={() => handleNotificationClick(notification.id)}>
                                                            <img src={openNotifications[notification.id] ? './../img/notice_arrow_up.png' :'./../img/notice_arrow_down.png'} />
                                                        </ModalCircle>
                                                    </ModalTitleWrap>
                                                    {
                                                        openNotifications[notification.id] &&
                                                        <ModalContentWrap $isopen={openNotifications[notification.id]}>
                                                            <p>{notification.content}</p>
                                                        </ModalContentWrap>
                                                    }
                                                </ModalWrap>
                                            )
                                        })
                                    }
                                </NoticeWrap>
                                :
                                <NoNoticeTxt>알림이 없습니다.</NoNoticeTxt>
                            }
                            {showGradient && <GradientOverlay />}
                        </ModalContainer>
                    </>
                }
                {/* PC 네비 시작 */}
                <NavLogo onClick={handleLogo}>
                    <img src='./../img/GMMT_logo_black.png' />
                </NavLogo>
                <NavBox> 
                    <MainMenu>
                        {
                            NavMenu.map((menuItem, menuIndex) => {
                                return(
                                    <MainMenuList
                                        key={menuIndex} 
                                        $visible={activeIndex === menuIndex} 
                                        onMouseEnter={() => handleMouseEnter(menuIndex)}
                                        onClick={()=>handleMainMenuClick(menuItem)}
                                        $entxt={menuItem.label === "My NFT"}
                                    >
                                        <p>{menuItem.label}</p>
                                        {
                                            isSubMenuOpen && activeIndex === menuIndex &&
                                            <SubMenu 
                                                onMouseEnter={() => setIsSubMenuOpen(true)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                            {
                                                menuItem.subMenu.map((subItem, subIndex) => {
                                                    return(
                                                        <SubMenuList
                                                            key={subIndex}
                                                            onClick={(e) => handleSubMenuClick(e,subItem)}
                                                        >
                                                            <span>{subItem}</span>
                                                        </SubMenuList>
                                                    )
                                                })
                                            }
                                            </SubMenu>
                                        }
                                    </MainMenuList>
                                )  
                            })
                        }
                    </MainMenu>
                    <NavIconBox>
                        {/* <NavLanBox>
                            <LanguageSelector langList={['kr']}/>
                        </NavLanBox> */}
                        <NotiIcon onClick={()=>{handleOpenNoticeModal();setIsSubMenuOpen(false);setActiveIndex(null);}}>
                            {
                                data?.unreadNotifications && data?.unreadNotifications.length > 0 &&
                                <span>N</span>
                            }
                            <img src='./../img/common_ico_noti.png' />
                        </NotiIcon>
                        {/* <img onClick={()=>{alert("준비 중입니다.")}} className='cart' src='./../img/common_ico_cart.png' /> */}
                        <NavIconTextBox onClick={handleLogout}>
                            <p>LOGOUT</p>
                        </NavIconTextBox>
                    </NavIconBox>
                </NavBox>
                {/* PC 네비 끝 */}
                {/* Mobile 네비 시작 */}
                <MnavBox>
                    <Overlay
                        $isactive={isActive}
                        onClick={() => setIsActive(false)} />
                    <MnavContainer>
                        <MnavQuick>
                            {/* <NavLanBox>
                                <LanguageSelector langList={['kr']} style={{zIndex: '0'}}/>
                            </NavLanBox> */}
                            <Hamburger
                                className={isActive && 'on'}
                                onClick={() => {
                                    if (!isNotice) {
                                        setIsActive(!isActive);
                                    }
                                }}
                            >
                                {
                                    Array(3).fill().map((_,i)=>{
                                        return (
                                            <div key={i}></div>
                                        )
                                    })
                                }
                            </Hamburger>
                        </MnavQuick>
                        <MnavWrap $isactive={isActive}>
                            <MnavTop>
                                <MnavLogo onClick={handleLogo}>
                                    <img src='./../img/GMMT_logo_black.png' />
                                </MnavLogo>
                                <MnavImgWrap>
                                    <NotiIcon onClick={()=>{setIsActive(false);handleOpenNoticeModal();}}>
                                        {
                                            data?.unreadNotifications && data?.unreadNotifications.length > 0 &&
                                            <span>N</span>
                                        }
                                        <img src='./../img/common_ico_noti.png' />
                                    </NotiIcon>
                                    {/* <MnavImgBox>
                                        <img onClick={()=>{alert("준비 중입니다.")}} className='cart' src='./../img/common_ico_cart.png' />
                                    </MnavImgBox> */}
                                </MnavImgWrap>
                            </MnavTop>
                            <ul>
                                {
                                    NavMenu.map((e,i)=>{
                                        return(
                                            <React.Fragment key={i}>
                                                <li onClick={()=>{clickMobileMainMenu(i)}}>
                                                    {e.label}
                                                </li>
                                                <MnavSubMenuWrap $isopen={isSubMenuOpen && activeIndex === i}>
                                                    {
                                                        e.subMenu.map((subItem, subIndex) => {
                                                            return (
                                                                <MnavSubMenuTxt
                                                                    key={subIndex}
                                                                    onClick={(e) => handleSubMenuClick(e,subItem)}
                                                                >
                                                                    {subItem}
                                                                </MnavSubMenuTxt>
                                                            )
                                                        })
                                                    }
                                                </MnavSubMenuWrap>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                            <MnavIconTxtBox onClick={() => {
                                document.body.style.overflow = 'auto'
                                handleLogout();
                            }}>
                                <p>LOGOUT</p>
                            </MnavIconTxtBox>
                        </MnavWrap>
                    </MnavContainer>
                </MnavBox>
                {/* Mobile 네비 끝 */}
            </NavContainer>
        </>
    )
}

export default Header
