import axios from 'axios';
import { ROUTER_PATHS } from "../constants/routerPaths";
import { ERROR_CODE, ERROR_CODE_DETAIL } from "../constants/errorCode";
import log from 'loglevel';


const apiClient = axios.create({
    baseURL: 'https://api.gmmtstaking.com',
    // baseURL: '',
    withCredentials: true,
    // headers: {
    //     'Access-Control-Allow-Origin': `${process.env.REACT_APP_API_URL}`,
    // }
});

apiClient.interceptors.request.use(
    
    (config) => {
        // console.log("TEST");
        // const token = document.cookie.split('; ').find(row => row.startsWith('accessToken='));
        // if (token) {
        //     config.headers['accessToken'] = token.split('=')[1];
        // }
        const accessToken = localStorage.getItem('accessToken');
        log.info("REQEUST ACCESS TOKEN: ", accessToken);
        if (accessToken) {
            // log.info('accessToken: ', accessToken);
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        log.error('REQUEST ERROR: ', error);
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        log.info('RESPONSE: ', response);

        const accessToken = response.headers['authorization']?.split(' ')[1];
        const refreshToken = response.headers['refresh']?.split(' ')[1];
        // console.log("response: accessToken: ", accessToken);
        // console.log("response: refreshToken: ", refreshToken);

        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
        }
        if (refreshToken) {
            localStorage.setItem('refreshToken', refreshToken);
        }
        return response;
    },
    async (error) => {
        if(!error.response) {
            log.error('RESPONSE ERROR: ', error);
            // console.log("RESPONSE ERROR: ", error);
        }else {
            log.error('RESPONSE ERROR: ', error.response);
            // console.log("RESPONSE ERROR: ", error.response);    

            if (error.response.status === ERROR_CODE.UNAUTHORIZED) {
                log.error('RESPONSE ERROR 401: ', error.response.data);
                // console.log("RESPONSE ERROR 401: ", error.response.data);    
                log.error(`DETAILS: ${error.response.data.code} ${error.response.data.response_message}`);
                const originalRequest = error.config;
                try {
                    // // localStorage get refresh token
                    // const accessToken = localStorage.getItem('accessToken');
                    const refreshToken = localStorage.getItem('refreshToken');
                    // console.log("request: refreshToken: ", refreshToken)
                    if (!refreshToken) {
                        log.error('REFRESH TOKEN NOT FOUND');
                        window.location.replace(ROUTER_PATHS.LOGIN);
                    }
                    if(error.response.data.code === ERROR_CODE_DETAIL.ACCESS_TOKEN_EXPIRED) {
                        log.error('ACCESS_TOKEN_EXPIRED -> ACCESS KOEN AND REFRESH TOKEN REISSUE');
                        // console.log("ACCESS_TOKEN_EXPIRED -> ACCESS KOEN AND REFRESH TOKEN REISSUE");  
                        // originalRequest.headers['authorization'] = `Bearer ${accessToken}`;
                        originalRequest.headers['Refresh'] = `Token ${refreshToken}`;
                        return apiClient(originalRequest);
                    }else if(error.response.data.code === ERROR_CODE_DETAIL.INVALID_TOKEN) {
                        log.error('INVALID_TOKEN');
                        window.location.replace(ROUTER_PATHS.LOGIN);
                    }else if(error.response.data.code === ERROR_CODE_DETAIL.REFRESH_TOKEN_EXPIRED) {
                        log.error('REFRESH_TOKEN_EXPIRED');
                        window.location.replace(ROUTER_PATHS.LOGIN);
                    }else {
                        log.error('ELSE ERROR 401:');
                        window.location.replace(ROUTER_PATHS.LOGIN);
                    }

                    // localStorage.removeItem('accessToken');
                    // localStorage.removeItem('refreshToken');
                    // window.location.replace(ROUTER_PATHS.LOGIN);
                    // const response = await apiClient.post('/api/auth/refresh', {}, {
                    //     headers: {
                    //         'refreshToken': refreshToken
                    //     }
                    // });
                    // localStorage.setItem('accessToken', response.data.accessToken);
                    //
                    // originalRequest.headers['accessToken'] = response.data.accessToken;
                    // return apiClient(originalRequest);
                    // const refreshToken = document.cookie.split('; ').find(row => row.startsWith('refreshToken=')).split('=')[1];
                    // const response = await apiClient.post('/api/auth/refresh', {}, {
                    //     headers: {
                    //         'refreshToken': refreshToken
                    //     }
                    // });
                    //
                    // const newAccessToken = response.data.accessToken;
                    // document.cookie = `accessToken=${newAccessToken}; path=/; HttpOnly`;

                    // originalRequest.headers['accessToken'] = newAccessToken;
                    // return apiClient(originalRequest);
                } catch (error) {
                    log.error('RESPONSE 401 CATCH: ', error);
                    // if (refreshError.response && refreshError.response.status === 401) {
                    //     log.error('REFRESH ERROR 401: ', refreshError.response.data);
                    //     // window.location.replace(ROUTER_PATHS.LOGIN);
                    // }
                    // return Promise.reject(refreshError);
                    // window.location.replace(ROUTER_PATHS.LOGIN);
                }
            }else if(error.response.status === ERROR_CODE.BAD_REQUEST) {
                log.error('RESPONSE ERROR 400: ', error.response.data);
                return Promise.reject(error);
            }

            else if(error.response.status === ERROR_CODE.FORBIDDEN) {
                log.error('RESPONSE ERROR 403: ', error.response.data);
                window.location.replace(ROUTER_PATHS.LOGIN);

            }else if(error.response.status === ERROR_CODE.NOT_FOUND) {
                log.error('RESPONSE ERROR 404: ', error.response.data);
                // window.location.replace(ROUTER_PATHS.NOT_FOUND);
            }
            else if(error.response.status === ERROR_CODE.INTERNAL_SERVER_ERROR) {

                log.error('RESPONSE ERROR 500: ', error.response.data);
                // window.location.replace(ROUTER_PATHS.ERROR);
            }
        }
        return Promise.reject(error);
        // window.location.replace(ROUTER_PATHS.ERROR);
    }
);

export default apiClient;
