import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import QrCode from '../components/qr/QrCode';
import { friend } from '../features/auth/friendSlice';
import Footer from '../components/layout/Footer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 0 20px;
    background-color: #FAFFF1;
    @media (max-width: 768px) {
        justify-content: start;
        padding-top: 100px;
    }
`;

const Title = styled.div`
    font-size: 34px;
    margin-bottom: 77px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    @media (max-width: 768px) {
        margin-bottom: 28px;
        font-size: 20px;
    }
`;

const QRContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const QRCodeImg = styled.div`
    margin-right: 15px;
    
    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 44px;
    
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 148px;
    justify-content: center;
    align-items: start;
    border-top: 2px solid #666666;
    max-width: 1127px;
    width: 100%;
`;

const IdContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
    height: 100%;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }
`;

const IdLabel = styled.div`
    font-size: 16px;
    font-weight: 700;
    //line-height: 18.75px;
    margin-right: 35px;
    width: 120px;
    padding-left:15px;
    white-space: nowrap;
    
    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
        padding-left: 10px;
    }
`;

const IdValue = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #5B5B5B;
    margin-right: 20px;
    
    @media (max-width: 768px) {
        padding-left: 10px;
        padding-top:3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
        
    
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }
`;

const LinkLabel = styled.div`
    font-size: 16px;
    font-weight: 700;
    //line-height: 18.75px;
    margin-right: 35px;
    width: 120px;
    padding-left:15px;
    white-space: nowrap;

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
        padding-left: 10px;
       
    }
`;

const LinkValue = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #5B5B5B;
    margin-right: 20px;
    @media (max-width: 768px) {
        padding-left: 10px;
        padding-top:3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const CopyImgBtn = styled.button`
    /* width: 24px;
    height: 24px;
    border:0;
    background-color: transparent; */
    padding: 5px;
    background-color: #16BFB6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`
const CopyImg = styled.img`
    width: 14px;
    height: 14px;
`

const ValueBox = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
`

const Vilage = () => {
    const dispatch = useDispatch();
    const { status, error, data } = useSelector((state) => state.friend);
    const copyToClipboard = (key) => {
        const textToCopy = key === "id" ? data?.esperoId : data?.esperoLink;
        // 임시 textarea 요소 생성
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);

        // 텍스트 선택
        textArea.select();
        textArea.setSelectionRange(0, 99999); // 모바일 장치에서도 동작하도록 설정

        // 클립보드에 복사
        document.execCommand('copy');

        // 임시 요소 제거
        document.body.removeChild(textArea);

        alert('클립보드에 복사되었습니다.');
    };

    useEffect(() => {
        dispatch(friend());
    }, []);

    return (
        <>
            <Container>
                <Header />
                <Title>Invite Friends</Title>
                <QRContainer>
                    <QRCodeImg>
                        <QrCode url={data?.esperoLink}/>
                    </QRCodeImg>
                    <InfoContainer>
                        <IdContainer>
                            <IdLabel>Referral ID</IdLabel>
                            <ValueBox>
                                <IdValue>{data?.esperoId}</IdValue>
                                <CopyImgBtn onClick={() => copyToClipboard("id")}>
                                    <CopyImg src="./../img/common_copy_ico.png" />
                                </CopyImgBtn>

                            </ValueBox>

                        </IdContainer>
                        <LinkContainer>
                            <LinkLabel>Referral Link</LinkLabel>
                            <ValueBox>
                                <LinkValue>{data?.esperoLink}</LinkValue>
                                <CopyImgBtn onClick={() => copyToClipboard("link")}>
                                    <CopyImg src="./../img/common_copy_ico.png" />
                                </CopyImgBtn>
                            </ValueBox>

                        </LinkContainer>
                    </InfoContainer>
                </QRContainer>
            </Container>
            <Footer $fixed />
        </>
    )
}

export default Vilage;