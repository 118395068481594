import React from 'react';
import styled from 'styled-components';

const RadioWrapper = styled.label`
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  font-weight: 700;
  color: ${(props) => (props.checked ? '#1C1C1CD9' : '#1C1C1C99')};
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked + span:after {
    display: block;
  }
`;

const RadioIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1.5px solid #D2D2D2;
  background-color: #fff;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #16BFB6;
  }
`;

const RadioBtn = ({ name, value, checked, onChange, children }) => {
    return (
      <RadioWrapper checked={checked}>
        <RadioInput
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <RadioIcon checked={checked}/>
        {children}
      </RadioWrapper>
    );
  };

export default RadioBtn