import { createSlice } from '@reduxjs/toolkit';

import log from 'loglevel';
import API_URL from '../../constants/apiUrl';
import REDUX_STATUS from '../../constants/reduxStatus';
import { createApiThunk } from '../../utils/apiThunk';

export const walletCreateApi = createApiThunk('wallet/create', API_URL.WALLET_CREATE, 'get');

const walletSlice = createSlice({

    name: 'wallet',
    initialState: {
        walletStatus: 'idle',
        walletError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(walletCreateApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.walletStatus = REDUX_STATUS.LOADING;
            })
            .addCase(walletCreateApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.walletStatus = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(walletCreateApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.walletStatus = REDUX_STATUS.FAILED;
                state.walletError = action.payload.message;
                alert(action.payload.message);
            });
    },
});

export default walletSlice.reducer;
