import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ROUTER_PATHS } from "./constants/routerPaths";
import { Helmet } from 'react-helmet';
import Login from './pages/auth/login';
import Join from './pages/auth/join';
import Reset from './pages/auth/reset';
import Vilage from './pages/Vilage';
import DashBoard from './pages/DashBoard';
import ProtectedRoute from './ProtectedRoute';
import MyInfo from './pages/MyInfo';
import LineTree from './pages/LineTree';
import Board from './pages/Board';
import BoardDetail from './pages/BoardDetail';
import UrlRefresh from './components/UrlRefresh';
import StakingRequest from './pages/StakingRequest';
import WalletMain from './pages/WalletMain';
import CreditList from './pages/CreditList';
import StakingList from './pages/StakingList';
import SwapRequest from './pages/SwapRequest';
import SwapList from './pages/SwapList';
import ProfitList from './pages/ProfitList';
import ProfitDetailList from './pages/ProfitDetailList';

const AppRoutes = () => {

    useEffect(() => {
        const handlePopState = () => {
            window.location.reload();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const [favicon, setFavicon] = useState("https://gmmtstaking.s3.ap-northeast-2.amazonaws.com/favicon.ico");

    return (
        <Router>
            <UrlRefresh />
            <Helmet>
                <link rel="icon" href={favicon} type="image/png" />
            </Helmet>
            <Routes>
                <Route path={ROUTER_PATHS.VILAGE} element={<ProtectedRoute element={<Vilage />} />} />
                <Route path={ROUTER_PATHS.LOGIN} element={<Login/>} />
                <Route path={ROUTER_PATHS.JOIN} element={<Join/>} />
                <Route path={ROUTER_PATHS.RESET} element={<Reset/>} />
                <Route path={ROUTER_PATHS.MYINFO}  element={<ProtectedRoute element={<MyInfo />} />} />
                <Route path={ROUTER_PATHS.DASHBOARD} element={<ProtectedRoute element={<DashBoard />} />} />
                <Route path={ROUTER_PATHS.LINE_TREE} element={<ProtectedRoute element={<LineTree />} />} />
                <Route path={ROUTER_PATHS.WALLET_MAIN} element={<ProtectedRoute element={<WalletMain />} />} />
                <Route path={ROUTER_PATHS.CREDIT_LIST} element={<ProtectedRoute element={<CreditList />} />} />
                <Route path={ROUTER_PATHS.STAKING_REQUEST} element={<ProtectedRoute element={<StakingRequest />} />} />
                <Route path={ROUTER_PATHS.STAKING_LIST} element={<ProtectedRoute element={<StakingList />} />} />
                <Route path={ROUTER_PATHS.SWAP_LIST} element={<ProtectedRoute element={<SwapList />} />} />
                <Route path={ROUTER_PATHS.SWAP_REQUEST} element={<ProtectedRoute element={<SwapRequest />} />} />
                <Route path={ROUTER_PATHS.PROFIT_LIST} element={<ProtectedRoute element={<ProfitList />} />} />
                <Route path={ROUTER_PATHS.PROFIT_DETAIL_LIST} element={<ProtectedRoute element={<ProfitDetailList />} />} />
                <Route path={ROUTER_PATHS.BOARD} element={<ProtectedRoute element={<Board />} />} />
                <Route path={ROUTER_PATHS.BOARD_DETAIL} element={<ProtectedRoute element={<BoardDetail />} />} />

            </Routes>
        </Router>
    );
};

export default AppRoutes;