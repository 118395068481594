import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { dashBoardApi } from '../features/dashBoardSlice';
import STATUSES from '../constants/reduxStatus';
import Ticker from '../components/Ticker';
import Popup from '../components/layout/Popup';
import { popupApi, checkIgnoreNotice, setIgnoreNotice } from '../features/auth/pageSlice';
import Loading from '../components/loading/Loading';
import { addCommasToNumber, formatBalance } from '../utils/utils';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { useDeviceDetect } from '../components/utils/utils';


const LayoutBg = styled.div`
    background: url('/img/bgimg.png') no-repeat center;
    background-size: cover;
    padding-top: 100px;
    min-height: 100vh;
    width: 100%; 
    @media (max-width:1300px){
        /* height: 100%; */
    }
    @media (max-width:788px){
        padding-top: 60px;
    }
`

const SymbolLogo = styled.div`
    position: fixed;
    right: 10%;
    top: 8%;
    @media (max-width: 788px){
        top: 200px;
        right: 0;
        img{
            width: 309px;
            height: 309px;
        }
    }
`

const DashBoardTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 160px;
    p{
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 4.102rem;
        text-align: center;
        background: linear-gradient(90.59deg, #16BFB6 36.97%, #A6FF1B 50.54%, #16BFB6 90.17%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 60px;
        @media (max-width:788px){
            font-size: 2.5rem;
            line-height: 2.93rem;
            margin-bottom: 30px;
        }
    }
    @media (max-width:788px){
        padding-top: 90px;
    }
`

const CountDownTitle = styled.div`
    padding-bottom: 15px;
    color: #16BFB6;
    font-size: 1.35rem;
    @media (max-width:780px) {
        font-size: 1.15rem;
    }
`

const LayoutContainer = styled.div`
    max-width: 1290px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    position: relative;
    height: 100%;
    @media (max-width:1300px){
        padding: 0 20px;
    }
`

const CoinLayout = styled.div`
    width: 100%;
    border: 2px solid #fff;
    background-color: #FFFFFF80;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-top: 65px; 
    @media (max-width:1300px){
        flex-wrap: wrap;
    }
    @media (max-width:788px){
        margin-top: 65px;
    }
`

const MyInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center; 
    margin-top: 15px; 
    gap: 15px;
    padding-bottom: 150px;
    @media (max-width:1300px){
        flex-wrap: wrap;
    }
    @media (max-width:788px){
        margin-top: 30px; 
    }
`

const MyInfoBox = styled.div`
    width: 100%;
    height: 230px;
    padding: 30px 0px;
    &:nth-child(3){
        padding: 34px 0px 30px 0px;
    }
    border-radius: 10px;
    background-color: #FFFFFFB2;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media (max-width:1300px){
        width: 48.9%;
    }
    @media (max-width:788px){
        width: 100%;
    }
`

const MyInfoTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #AAAAAA;
    }
    img{
        width: 32px;
        height: ${(props)=>props.$index === 2 ? '23px' : '32px'};
    }
`

const MyInfoTitle = styled.p`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: ${(props)=>props.$index === 2 ? '19px' : '14px'};
`

const MyInfoBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #666666;
    }
    span{
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1.5rem;
        color: #16BFB6;
        margin-top: 5px;
    }
`

const DashBoardFooter = styled.div`
    width: 100%;
    padding: 43px 0;
    /* position: fixed;
    bottom: 0; */
    display: flex; 
    align-items: center;
    justify-content: center;
    p{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.026rem;
        text-align: center;
        color: #AAAAAA;
    }
    @media (max-width:1300px){
        /* position: relative; */
    }
    @media (max-width:780px){
        padding: 32px 20px;
    }
`

function DashBoard() {
    
    const dispatch = useDispatch();
    const { data, status, error } = useSelector((state) => state.dashboard);
    const { popup, ignorePopup } = useSelector((state) => state.page.data);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const isMobile = useDeviceDetect();

    useEffect(() => {
        dispatch(dashBoardApi());
    }, [dispatch]);

    // useEffect(() => { // dispatch

    //     const getPopup = async () => { 
    //         await dispatch(popupApi());
    //         dispatch(checkIgnoreNotice());
    //     }

    //     getPopup(); 

    // }, [dispatch]);

    useEffect(() => {

        // console.log('popup : ', popup)
        // console.log('ignorePopup : ', ignorePopup)

        if (ignorePopup || JSON.stringify(popup) === '{}') { // 공지 없음
            setIsPopupOpen(false);
        } else { // 공지 있음
            setIsPopupOpen(true);
        }

    }, [popup, ignorePopup]);


    const myInfoDataList = [
        {
            "img": "./../img/common_gmmt_logo.png",
            "title": "GMMT 잔액",
            "percent": "",
            "subTitle": "GMMT",
            "value": formatBalance(data?.gmmtBalance)
        },
        {
            "img": "./../img/common_gmmt_logo.png",
            "title": "GMMT 총누적",
            // "percent": `(${data?.percentage === 0 ? '0' : (data?.percentage ?? 0).toFixed(2)}%)`,
            "percent": '(0%)',
            "subTitle": "GMMT",
            "value": '0'
            // "value": (data?.totalPoint ?? 0).toFixed(4)
        },
        {
            "img": "./../img/common_friend_ico.png",
            "title": "My Referrals",
            "percent": "",
            "subTitle": "명",
            "value": data?.myFriend ?? "0"
        },
        {
            "img": "./../img/common_eth_ico.png",
            "title": "Ethereum 시세",
            "percent": "",
            "subTitle": "USD",
            "value": (data?.eth ?? 0).toFixed(4)
        }
    ];

    // targetTime을 상태로 설정 (2024년 10월 17일 16:40 기준)
    const [targetTime, setTargetTime] = useState(new Date(2024, 9, 22, 9, 0).getTime());

    // 카운트다운이 완료되면 7일을 더해서 타겟 시간을 업데이트
    const handleComplete = () => {
        const newTargetTime = new Date(targetTime);
        newTargetTime.setDate(newTargetTime.getDate() + 7); // 7일 더함
        setTargetTime(newTargetTime.getTime()); // 상태 업데이트
    };

    return (
        <>
        {status === STATUSES.LOADING && <Loading />}
            {isPopupOpen &&
            <Popup
                title={popup.title}
                closeCallback={() => setIsPopupOpen(false)}
                customButton={
                    <button onClick={() => {
                        setIsPopupOpen(false);
                        dispatch(setIgnoreNotice(popup.id));
                    }}>
                        하루동안 보지않기
                    </button>
                }
            
            >
                <div dangerouslySetInnerHTML={{__html: popup.content}} />
            </Popup>}

            <Header />
            <LayoutBg>
                {/* <SymbolLogo>
                    <img src='./../img/logo_espero_symbol.png' />
                </SymbolLogo> */}
                <LayoutContainer>
                    <DashBoardTitle>
                        <p>You are history makers.</p>
                    </DashBoardTitle>
                    <CountDownTitle>Next Staking Activation Count down</CountDownTitle>
                    <FlipClockCountdown
                        to={targetTime}
                        hideOnComplete={false}
                        labelStyle={{ fontSize: isMobile ? 13 : 16}}
                        digitBlockStyle={{ width: isMobile ? 28 : 36, height: isMobile ? 60 : 70, fontSize : isMobile ? 30 : 33 , backgroundColor : 'white', color : '#000',boxShadow : '0 0 10px 0 rgba(0,0,0,0.1)'}}
                        dividerStyle={{ color: '#EBEBEB'}}
                        separatorStyle={{ color: '#16bfb6', size: '5px' }}
                        onComplete={handleComplete} />
                    <CoinLayout>
                        <Ticker />
                    </CoinLayout>
                    <MyInfo>
                        {
                            // status === STATUSES.SUCCEEDED &&
                            myInfoDataList.map((info,index)=>{
                                return(
                                     <MyInfoBox key={index}>
                                        <MyInfoTop $index={index}>
                                            <img src={info.img} />
                                            <MyInfoTitle $index={index}>
                                                {info.title}
                                            </MyInfoTitle>
                                            <span>{info.percent}</span>
                                        </MyInfoTop>
                                        <MyInfoBottom>
                                            <p>{info.subTitle}</p>
                                            <span>{addCommasToNumber(info.value)}</span>
                                        </MyInfoBottom>
                                    </MyInfoBox>
                                )
                            })
                        }
                    </MyInfo>
                </LayoutContainer>
                <DashBoardFooter>
                    <p>© 2024 GMMTSTAKING. All rights reserved.</p>
                </DashBoardFooter>
            </LayoutBg>
        </>
    )
}

export default DashBoard;