import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../utils/apiThunk";
import REDUX_STATUS from "../constants/reduxStatus";
import API_URL from "../constants/apiUrl";
import log from 'loglevel';

export const withdrawApplyApi = createApiThunk('withdraw/apply', API_URL.WITHDRAW_APPLY, 'post');
export const withdrawListApi = createApiThunk('withdraw/list', API_URL.WITHDRAW_LIST, 'get');

const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState: {
        status: 'idle',
        error: null,
        data: null
    },

    extraReducers: (builder) => {
        builder
            .addCase(withdrawApplyApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(withdrawApplyApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(withdrawApplyApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(withdrawListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(withdrawListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(withdrawListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});

export default withdrawSlice.reducer;
