import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import pageReducer from '../features/auth/pageSlice';
import dashBoardReducer from './../features/dashBoardSlice';
import myInfoReducer from './../features/myInfoSlice';
import boardReducer from './../features/boardSlice';
import withdrawReducer from './../features/withdrawSlice';
import friendReducer from '../features/auth/friendSlice';
import joinReducer from '../features/auth/joinSlice';
import stakingReducer from './../features/staking/stakingSlice';
import swapReducer from '../features/swapSlice';
import walletReducer from '../features/wallet/walletSlice';
import profitReducer from '../features/profit/profitSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        join: joinReducer,
        page: pageReducer,
        dashboard : dashBoardReducer,
        myinfo : myInfoReducer,
        friend: friendReducer,
        board : boardReducer,
        withdraw : withdrawReducer,
        staking: stakingReducer,
        swap: swapReducer,
        wallet : walletReducer,
        profit : profitReducer
    },
});

export default store;