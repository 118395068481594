import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Pagination from '../components/pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { stakingRequestListApi } from '../features/staking/stakingSlice';
import { addCommasToNumber, getUtcTimeYmd, getUtcTimeYmdHms, localDateTimeToText } from '../utils/utils';
import { useDeviceDetect } from '../components/utils/utils';
import STATUSES from '../constants/reduxStatus';
import Loading from '../components/loading/Loading';
import { swapListApi } from '../features/swapSlice';
import { useRevalidator } from 'react-router-dom';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  padding-bottom: 16px;
  border-bottom: 2px solid #666666;
  @media (max-width:780px){
    padding-bottom: 10px;
  }
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  thead tr{
    padding: 15px;
  }
  thead tr th{
    height: 51px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.318rem;
    color: #000;
  }
  tbody tr td{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    height: 51px;
  }
  tr{
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  th,td{
    min-width: 100px;
    text-align: center;
    &.profit {
      color: #FF0E0E;
    }
  }
  @media (max-width:880px){
    display: none;
  }
`

const TableState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableWalletAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const TableTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const StatePendingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

// 대기중
const StatePending = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #A9A9A9;
`
// 대기중 옆에 취소버튼
const StatePendingCancel = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #FF0E0E;
  color: #fff;
  border-radius: 15px;
  padding: 5px 12px;
  height: 29px;
  cursor: pointer;
  @media (max-width:880px) {
    padding: 3px 12px;
    height: 25px;
  }
`

const StateFailed = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #FF0E0E;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  min-width: 70px;
  height: 29px;
`

// 진행중
const StateProgress = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #000;
`

// 완료
const StateComplete = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #16BFB6;
`

const MtableWrap = styled.div`
  padding: 10px 5px 0px 5px;
  @media (min-width:881px){
    display: none;
  }
`

const MtableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.total {
    justify-content: flex-end;
  }
`

const MtableBottom = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid #D9D9D9;
`

const MtableDate = styled.div`
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  color: #222222;
`

const MtablTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  color: #222222;
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  span {
    &.profit {
      color: #FF0E0E;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
  p{
    font-weight: 700;
  }
`

const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  color: #666666;
  padding: 150px 0px;
  @media (max-width:880px){
    padding: 180px 0px;
  }
`

function SwapList() {

  const dispatch = useDispatch();
  const isMobile = useDeviceDetect(880);
  const { status, error, data } = useSelector((state) => state.swap);
  // 페이지 번호
  const [pageNum, setPageNum] = useState(1);
  // 한 페이지에 보여줄 게시글 수
  const pageSize = 10;
  // 모바일 스테이킹 내역 배열
  const [detailList, setDetailList] = useState([]);

  const fetchData = async (page) => {
    try {
      setPageNum(page);
      const result = await dispatch(swapListApi({ page: pageNum }));
      const content = result.payload.content;

      if (page === 1) {
        setDetailList(content);
      } else {
        setDetailList(prevList => [...prevList, ...content]);
      }

    } catch (error) {
      console.log("스테이킹 내역 불러오는 중 오류 발생", error)
    }
  }


    function flagToElement (index) {
        switch (index) {
            case 0:
                return <StatePendingCancel>취소</StatePendingCancel>;
            case 1:
                return "신청";
            case 2:
                return <StatePending>대기중</StatePending>;
            case 3:
                return <StateComplete>완료</StateComplete>;
            case 4:
                return <StateFailed>실패</StateFailed>;
            default:
                return "";
        }
    }

  useEffect(() => {
    fetchData(pageNum);
  }, [dispatch, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [isMobile])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>Swap 신청내역</Title>
          <ContentTitle>신청 내역</ContentTitle>
          <TableContainer>
            {
              status === STATUSES.SUCCEEDED &&
              data?.content?.length === 0 ?
              <TableNoData>신청 내역이 없습니다.</TableNoData>
              :
              <Table>
                <colgroup>
                    <col></col>
                    <col></col>
                    <col></col>
                    <col></col>
                </colgroup>
                <thead>
                  <tr>
                    <th>신청일</th>
                    <th>승인일</th>
                    <th>신청 수량</th>
                    <th>수수료</th>
                    <th>Swap GMMT</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.content && data?.content?.length > 0 &&
                    data?.content?.map((item, index) => {
                    //   const postNumber = data?.totalElements - (pageNum - 1) * pageSize - index;
                      return (
                        <tr key={index}>
                          <td>{localDateTimeToText(item?.createdAt) || ''}</td>
                          <td>{item?.approvalDate ? getUtcTimeYmdHms(item.approvalDate) : '-'}</td>
                          <td>
                            <TableTrxId>
                              <p>{addCommasToNumber(item?.amount) || '0'}</p>
                            </TableTrxId>
                          </td> 
                          <td>
                            <TableTrxId>
                              <p>{addCommasToNumber(item?.swapFee) || '0'}</p>
                            </TableTrxId>
                          </td> 
                          <td>
                            <TableTrxId>
                              <p>{
                              addCommasToNumber(
                                Number.isInteger(item?.amount - item?.swapFee) 
                                  ? item?.amount - item?.swapFee 
                                  : (item?.amount - item?.swapFee).toFixed(4)) || '0'}</p>
                            </TableTrxId>
                          </td> 
                          <td>{
                            <StatePendingWrap>
                                {flagToElement(item?.flag)}
                            </StatePendingWrap> || ''}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            }
            {/* 모바일 내역 */}
            {
              isMobile &&
              detailList.map((item, index) => {
                return (
                  <MtableWrap key={index}>
                    <MtableTop>
                      <MtableDate>{item?.id}:::::{getUtcTimeYmdHms(item?.createdAt) || ''}</MtableDate>
                      <StatePendingWrap>
                        {flagToElement(item?.flag)}
                      </StatePendingWrap>
                    </MtableTop>
                    <MtableBottom>
                      <MtablTrxId>
                        <p>신청수량</p>
                        <span>{addCommasToNumber(item?.amount) || '0'}</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>수수료</p>
                        <span>{item?.swapFee || '0'}</span>
                      </MtablTrxId>
                      <MtablTrxId>
                        <p>Swap GMMT</p>
                        <span>{addCommasToNumber((item?.amount - item?.swapFee).toFixed(4)) || '0'}</span>
                      </MtablTrxId>
                      <MtablTrxId>
                            <p>승인일</p>
                            <span>{item?.approvalDate ? localDateTimeToText(item.approvalDate) : '-'}</span>
                      </MtablTrxId>

                      {/* <MtablTrxId>
                        <p>flag값?</p>
                        <span>{item?.flag || ''}</span>
                      </MtablTrxId> */}
                    </MtableBottom>
                  </MtableWrap>
                )
              })
            }
          </TableContainer>
          <Pagination
            onChange={(num) => setPageNum(num)}
            data={data?.content && data}
            putPageNum={pageNum}
          />
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default SwapList