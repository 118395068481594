import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Pagination from '../components/pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { walletCreateApi } from '../features/wallet/walletSlice';
import { userInfoApi } from '../features/myInfoSlice';
import QrCode from '../components/qr/QrCode';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import { useDeviceDetect } from '../components/utils/utils';
import { dashBoardApi } from '../features/dashBoardSlice';
import { addCommasToNumber, formatBalance, handleCopy, shortenTxt, shortenTxtMobile, shortenTxtPc } from '../utils/utils';
import ModalPw from '../components/modal/ModalPw';
import { withdrawApplyApi, withdrawListApi } from '../features/withdrawSlice';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const CreditWrap = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 60px;
    padding-bottom: 80px;
    @media (max-width:780px) {
        flex-wrap: wrap;
        gap: 30px;
        padding-bottom: 40px;
    }
`

const CreditIconWrap = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 50%;
    justify-content: center;
    padding: 65px 0px;
    @media (max-width:780px) {
        flex-basis: 100%;
        justify-content: center;
        padding: 30px 0px;
    }
`

const CreditIcon = styled.div`
    width: 280px;
    height: 280px;
    &.icon {
        width: 30px;
        height: 30px;
    }
    img {
        width: 100%;
    }
    @media (max-width:780px) {
        width: 200px;
        height: 200px;
    }
`

const CreditRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;
    @media (max-width:780px) {
        flex-basis: 100%;
    }
`

const CreditRightTitleWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 30px;
    width: 100%;
    @media (max-width:780px) {
        padding-bottom: 20px;
    }
`

const CreditRightTitle = styled.div`
    font-size: 2rem;
    font-weight: bold;
    @media (max-width:780px) {
        font-size: 1.25rem;
    }
`

const CreditBorder = styled.div`
    background-color: #FFFFFFB2;
    border: 2px solid #16BFB6;
    border-radius: 10px;
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 15px;
    @media (max-width:780px) {
        padding: 15px 20px;
    }
`

const CreditBorderTitle = styled.div`
    padding-bottom: 10px;
    font-size: 1.25rem;
    font-weight: 600;
    @media (max-width:780px) {
        font-size: 1.125rem;
    }
`

const CreditBorderCnt = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    p {
        font-size: 1.563rem;
        font-weight: bold;
        word-break: break-all;
        text-align: right;
    }
    span {
        font-size: 1.375rem;
        margin-left: 10px;
        color: #16BFB6;
        font-weight: bold;
    }
    @media (max-width:780px) {
        flex-wrap: wrap;
        p {
            font-size: 1.375rem;
        }
        span {
            font-size: 1.25rem;
        }
    }
`

const CreditBtnWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    width: 100%;
    @media (max-width:780px) {
        gap: 15px;
    }
`

const CreditBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #16BFB6;
    color: #fff;
    flex-basis: 50%;
    border-radius: 10px;
    padding: 20px 0px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    @media (max-width:780px) {
        padding: 15px 0px;
        font-size: 0.875rem;
    }
    img {
        width: 20px;
        height: 20px;
    }
`

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  padding-bottom: 16px;
  border-bottom: 2px solid #666666;
  @media (max-width:780px){
    padding-bottom: 10px;
  }
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  thead tr{
    padding: 15px;
  }
  thead tr th{
    height: 51px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.318rem;
    color: #000;
  }
  tbody tr td{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    height: 51px;
  }
  tr{
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  th,td{
    text-align: center;
  }
  @media (max-width:880px){
    display: none;
  }
`

const TableState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableWalletAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const TableTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
`

const StateApproved = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #16BFB6;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  height: 29px;
`

const StatePending = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #BBBBBB;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  height: 29px;
`

const StateFailed = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #FF0E0E;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  height: 29px;
`

const MtableWrap = styled.div`
  padding: 15px 5px 0px 5px;
  @media (min-width:881px){
    display: none;
  }
`

const MtableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MtableBottom = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid #D9D9D9;
`

const MtableDate = styled.div`
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  color: #222222;
`

const MtableStateApproved = styled(StateApproved)`
  padding: 3px 12px;
  height: 25px;
`

const MtableStatePending = styled(StatePending)`
  padding: 3px 12px;
  height: 25px;
`

const MtableStateFailed = styled(StateFailed)`
  padding: 3px 12px;
  height: 25px;
`

const MtableCnt = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #222222;
    padding-bottom: 10px;
    font-size: 1rem;
    line-height: 1.172rem;
    &:last-child {
        padding-bottom: 0;
    }
    p{
        font-weight: 700;
    }
    span{
        font-weight: 400;
    }
`

const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  color: #666666;
  padding: 150px 0px;
  @media (max-width:880px){
    padding: 180px 0px;
  }
`

const ModalOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000A6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 640px;
    transform: translateX(-50%) translateY(-50%);
    background-color: #222222;
    border-radius: 10px;
    padding: 48px 30px 40px 30px;
    z-index: 51;
    &.delete {
        padding: 48px 30px 30px 30px;
    }
    &.otp {
        z-index: 55;
        width: 475px;
    }
    @media (max-width:780px){
        width: 320px;
        padding: 35px 20px 30px 20px;
    }
`

const ClostBtn = styled.div`
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 30px;
    @media (max-width:780px){
        top: 15px;
        right: 12px;
    }
`

const ModalTitle = styled.div`
    font-weight: 700;
    color: #fff;
    font-size: 1.75rem;
    line-height: 2.051rem;
    text-align: center;
    @media (max-width:780px){
        font-size: 1.5rem;
        line-height: 1.758rem;
    }
`

const ModalWrap = styled.div`
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 20px;
    height: 450px;
    overflow-y: auto;
    &.receive {
        height: auto;
    }
    &.delete{
        margin-top: 21px;
        text-align: center;
        @media (max-width:780px){
            margin-top: 12px;
            height: auto;
        }
    }
    @media (max-width:780px){
        margin-top: 12px;
        padding: 20px 15px 20px 15px;
    }
`

const ModalIconWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`

const ModalIcon = styled.div`
    width: 40px;
    height: 40px;
    img {
        width: 100%;
    }
`

const ModalContentTitle = styled.div`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-bottom: 10px;
    &.wallet {
        font-size: 1.25rem;
        text-align: center;
        padding-bottom: 25px;
    }
    &.borderB {
        border-bottom: 2px solid #666666;
    }
    &.price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #666666;
    }
    @media (max-width:780px){
        padding-bottom: 3px;   
        font-size: 1rem;
        line-height: 1.5rem;
    }
`

const PriceTxt = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
    p {
        color: #16BFB6;
        font-size: 1rem;
        font-weight: 400;
        &:first-child {
            font-size: 1.25rem;
            font-weight: bold;
        }
    }
    @media (max-width:780px) {
        p {
            font-size: 0.875rem;
            &:first-child {
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }
`

const PriceWrap = styled.div`
    padding: 8px 20px;
    font-size: 1rem;
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #aaa;
    &.total {
        color: #000;
        margin-bottom: 30px;
        font-weight: 700;
    }
    @media (max-width:780px) {
        font-size: 0.875rem;
        padding: 8px;
        &.total {
        color: #000;
            margin-bottom: 20px;
        }
    }
`

const ModalContent = styled.div`
    min-height: 80px;
    max-height: 200px;
    display: flex;
    justify-content: ${(props)=>props.$nodata ? 'center' : 'flex-start'};
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    @media (max-width:780px) {
        margin-bottom: 10px;
    }
`

const ModalRecentWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0px;
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    font-size: 1rem;
    @media (max-width:780px) {
        padding: 8px 0px;
        font-size: 0.875rem;
        gap: 10px;
    }
`

const ModalRecentIcon = styled.div`
    width: 30px;
    height: 30px;
    img {
        width: 100%;
    }
`

const ModalOtpWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    @media (max-width:780px){
        padding: 8px 0px 16px 0px;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }
    > img{
        width: 148px;
        height: 148px;
        @media (max-width:780px){
            width: 80px;
            height: 80px;
        }
    }
`

const ModalOtpTxt = styled.div`
   
`

const ModalOtpDesc = styled.div`
    color: #666;
    text-align: center;
    line-height: 1.5;
    @media (max-width : 780px) {
        font-size: 0.85rem;
        span {
            display: block;
        }
    }
`

const ModalOtpBox = styled.div`
    width: 100%;
    padding: 5px 15px;
    background-color: #F8F8F8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
    &.balance {
        margin: 10px 0px;
        padding: 20px 30px;
        @media (max-width:780px){
            padding: 10px 20px;
        }
    }
    p{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: #AAAAAA;
        @media (max-width:780px){
            font-size: 0.75rem;
        }
    }
    span {
        color: #4e4e4e;
        font-weight: bold;
    }
    img{
        cursor: pointer;
        width: 24px;
        height: 24px;
        @media (max-width: 780px){
            width: 20px;
            height: 20px;
        }
    }
    @media (max-width:780px){
        /* max-width: 164px; */
        margin: 8px 0px 11px 0px;
        padding: 4px 10px;
        &.balance {
            flex-wrap: wrap;
        }
    }
`

const ModalCoinValue = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
    p {
        color: #16BFB6;
        font-size: 1.5rem;
        font-weight: bold;
    }
    span {
        color: #16BFB6;
        font-weight: 500;
    }
    @media (max-width:780px) {
        p {
            font-size: 1rem;
        }
        span {
            font-size: 0.875rem;
        }
    }
`

const ModalOtpCopy = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: #16BFB6;
    border-radius: 4px;
    margin-left: 10px;
    @media (max-width:780px){
        width: 20px;
        height: 20px;
    }
    img{
        width: 14px;
        height: 14px;
        @media (max-width:780px){
            width: 11.67px;
            height: 11.67px;
        }
    }
`

const ContentBtn = styled.button`
    min-width: 107px;
    background-color: #16BFB6;
    border-radius: 5px;
    padding: 10px 20px;
    font-family: "NotoSansKR";
    font-weight: 700;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
    margin-left: 15px;
    @media (max-width:780px){
        min-width: 65px;
        font-size: 0.75rem;
        padding: 5px 10px;
        margin-left: 8px;
    }
`

const ModalAuthInputWrap = styled.div`
    margin: 10px 0px;
    width: 100%;
    display: flex;
    position: relative;
    img{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
`

const ModalAuthInput = styled.input`
    width: 100%;
    height: 45px;
    border: 1px solid #C4FF69;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #FFFFFFD9;
    color: #1F1F1F;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    &:focus{
        outline: none;
        border-color: #16BFB6;
        background: #fff;
    }
    &::placeholder{
        font-size: 1rem;
        line-height: 1.5rem;
        color: #6F6F6F;
    }
    @media (max-width:780px){
        padding: 5px 10px;
        height: 35px;
        &::placeholder{
            font-size: 0.75rem;
            line-height: 1.5rem;
            color: #6F6F6F;
            letter-spacing: -0.24px;
        }
    }
`

const ModalAuthBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 15px;
    @media (max-width:780px){
        margin-top: 10px;
    }
`

const ModalAuthBtn = styled.div`
    font-family: 'Roboto';
    width: 100%;
    padding: 16px 0;
    border-radius: 10px;
    color: #848484;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    cursor: pointer;
    &.next {
        background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
        color: #fff;
    }
    @media (max-width:780px){
        padding: 13px 0px;
    }
`

const ModalFrom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        font-size: 1.25rem;
        font-weight: bold;
    }
    @media (max-width:780px) {
        flex-wrap: wrap;
    }
`

const ModalFromLine = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    span {
        font-size: 1rem;
        color: #A9A9A9;
        &.line {
            width: 1px;
            height: 12px;
            background-color: #A9A9A9;
        }
    }
    @media (max-width:780px) {
        flex-wrap: wrap;
        span {
            font-size: 0.875rem;
        }
    }
`

const ModalAddress = styled.div`
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 10px;
    gap: 20px;
    padding: 15px 20px;
    margin: 10px 0px 20px 0px;
    img {
        width: 30px;
        height: 30px;
    }
    @media (max-width:780px) {
        padding: 10px;
        gap: 10px;
    }
`

const ModalAddressTxt = styled.div`
    color: #aaa;
    font-size: 0.875rem;
    word-break: break-all;
`

const ModalAuthWrap = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0px;
    justify-content: space-between;
    @media (max-width:780px){
        flex-wrap: wrap;
        padding: 12px 5px;
        &:last-child {
            padding: 0 5px;
        }
    }
`

function CreditList() {

    const dispatch = useDispatch();
    const isMobile = useDeviceDetect(880);

    const modalTopRef = useRef(null);
    const addressRef = useRef(null);
    const cntRef = useRef(null);

    const { data, status, error } = useSelector((state) => state.myinfo);
    const dashboardData = useSelector((state) => state.dashboard.data);
    const withdrawData = useSelector((state) => state.withdraw.data);

    // 페이지 번호
    const [pageNum, setPageNum] = useState(1);
    // 한 페이지에 보여줄 게시글 수
    const pageSize = 10;
    // 모바일 스테이킹 내역 배열
    const [detailList, setDetailList] = useState([]);
    // 받기 모달 
    const [isReceive, setIsReceive] = useState(false);
    // 보내기 모달
    const [isSend, setIsSend] = useState(false);
    // 출금 수량 
    const [cnt, setCnt] = useState("");
    // 받는 지갑 주소
    const [receiveAddress, setReceiveAddress] = useState("");
    // 다음 버튼 클릭
    const [isNext, setIsNext] = useState(false);
    // 보내기 모달 거래인증 눈
    const [eye, setEye] = useState([0, 0]);
    // 거래 비밀번호 모달
    const [isTradeModal, setIsTradeModal] = useState(false);
    // 구글 Otp 모달
    const [isOtpModal, setIsOtpModal] = useState(false);
    // 모달 구글 otp 코드
    const [modalOtp, setModalOtp] = useState("");
    // 모달 거래 비밀번호
    const [modalTrade, setModalTrade] = useState("");
    // input 구글 otp
    const [otp, setOtp] = useState("");
    // input 거래 비밀번호
    const [tradePw, setTradePw] = useState("");
    // 지갑 생성 로딩
    const [isLoading, setIsLoading] = useState(false);
    // 모달 버튼 텍스트
    const [buttonTxt, setButtonTxt] = useState('다음');
    // 최근 거래 내역 저장
    const [latestWithdrawals, setLatestWithdrawals] = useState([]);

    // 유효성 검사 걸리면 input focus
    const focusField = (index) => {
        const refs = [addressRef,cntRef];
        refs[index].current.focus();
    };

    // 거래 내역
    const fetchData = async (page) => {
        try {
            setPageNum(page);
            const result = await dispatch(withdrawListApi({ page: pageNum, pageSize: pageSize }));
            const content = result.payload.withdrawList.content;
    
          if (page === 1) {
            setLatestWithdrawals(content.slice(0, 3));
            setDetailList(content);
          } else {
            setDetailList(prevList => [...prevList, ...content]);
          }
    
        } catch (error) {
          console.log("지갑 거래 내역 불러오는 중 오류 발생", error)
        }
    };

    // 거래 비밀번호 , 구글 otp 눈 표시
    const toggleEye = (index) => {
        const newEye = [...eye];
        newEye[index] = !newEye[index];
        setEye(newEye);
    };

    // 출금 수량 onchange
    const handleCntChange = (e) => {
        const value = e.target.value;
        
        // 정수 또는 소수점 이하 4자리까지 허용
        const regex = /^\d+(\.\d{0,4})?$/;

        // 정규식에 맞는 경우에만 상태 업데이트
        if (regex.test(value) || value === '') {
            setCnt(value);
        }
    };

    // 최대 수량 클릭
    const maxCnt = () => {
      setCnt(formatBalance(dashboardData?.gmmtBalance));
    };

    // 받기 버튼 클릭
    const handleReceive = async () => {

        try {
            if (!data?.walletAddress || data.walletAddress === "") {
                setIsLoading(true);
                const create = await dispatch(walletCreateApi());
                if (create.type === 'wallet/create/rejected') {
                    setIsReceive(false);
                } else {
                    await dispatch(userInfoApi());
                    setIsReceive(true);
                    setIsLoading(false);
                }
                setIsLoading(false);
                
            } else {
                setIsReceive(true);
            } 

        } catch (error) {
            console.log("지갑 주소 생성 중 오류 발생",error);
        }
    };

    // 보내기 버튼 클릭
    const handleSend = () => {

        if(withdrawData?.withdraw_button === "0") {
            alert("현재 출금신청이 불가능합니다.");
            return;
        }

        if(!data?.walletAddress) {
            alert('지갑주소 생성 후 출금신청 가능합니다.');
            return;
        }

        if(!data?.otpSecretKey) {
            alert('OTP등록 후 출금신청 가능합니다.');
            return;
        }

        setIsSend(true);
        setReceiveAddress("");
        setEye([0,0]);
        setCnt("");
        setOtp("");
        setTradePw("");
    };

    // 보내기 모달창에서 다음 버튼 클릭
    const handleSendNext = () => {

        if (!receiveAddress || receiveAddress.trim() === "") {
            alert("받는 지갑 주소를 입력해 주세요.");
            focusField(0);
            return;
        }
        
        // 지갑 주소 유효성 검사 (0x로 시작하고 알파벳, 숫자만 포함 / 총 42자리)
        const addressRegex = /^0x[a-fA-F0-9]{40}$/;
        if (!addressRegex.test(receiveAddress)) {
            alert("올바른 지갑주소를 입력해주세요.");
            focusField(0);
            setReceiveAddress('');
            return;
        }

        if (!cnt || cnt.trim() === "") {
            alert("출금 수량을 입력해 주세요.");
            focusField(1);
            return;
        }

        if (cnt.split('.').length > 1 && cnt.split('.')[1].length === 0) {
            alert("출금 수량을 정확하게 입력해 주세요.");
            setCnt('');
            focusField(1);
            return;
        }

        if (cnt === "0.0000" || cnt === "0") {
            alert("출금 금액이 수수료보다 적습니다.");
            setCnt('');
            focusField(1);
            return;
        }

        if (withdrawData?.withdrawFee >= cnt) {
            alert("출금 금액이 수수료보다 적습니다.");
            setCnt('');
            focusField(1);
            return;
        }

        if(formatBalance(dashboardData?.gmmtBalance) < cnt) {
            alert("보유수량을 초과할 수 없습니다.");
            setCnt('');
            focusField(1);
            return;
        }

        setIsNext(true);
        setButtonTxt('보내기');

        // 최상단으로 스크롤
        if (modalTopRef.current) {
            modalTopRef.current.scrollTop = 0;
        }
    };

    // 보내기 모달창에서 보내기 버튼
    const handleSubmit = async () => {
        if(tradePw === ""){
            alert("거래 비밀번호를 입력해 주세요.")
            return;
        }

        if(otp === ""){
            alert("구글 OTP 번호를 입력해 주세요.")
            return;
        }
        
        try {

            // const response1 = ({
            //   receiveAddress : receiveAddress, // string
            //   amount : Number(cnt), // number
            //   otpNumber : otp, // string
            //   tradePw : tradePw // string
            // });

            // console.table(response1);
            setIsLoading(true);
            const response = await dispatch(withdrawApplyApi({
              receiveAddress : receiveAddress, // string
              amount : Number(cnt), // number
              otpNumber : otp, // string
              tradePw : tradePw // string
            }));
    
            if(response.type === 'withdraw/apply/fulfilled') {
                alert("출금이 완료되었습니다.");
                setIsSend(false);
                setIsNext(false);
                fetchData(pageNum);
            }
            setIsLoading(false);

        } catch(error) {
            console.error('출금 신청하기 중 오류 발생',error)
        }
    };

    // 구글 OTP input 클릭
    const handleOtpInput = () => {
        setIsOtpModal(true);
        setOtp("");
        setModalOtp("");
    };

    // 거래 비밀번호 input 클릭
    const handleTradePasswordClick = () => {
        setIsTradeModal(true);
        setTradePw("");
        setModalTrade("")
    };

    // 구글 Otp 마지막 번호 클릭
    const otpLastClick = (newOtp) => {
        setOtp(newOtp);
        setIsOtpModal(false);
    };

    // 거래 비밀번호 마지막 번호 클릭
    const handleTradeOtpSubmit = (newPw) => {
        setTradePw(newPw);
        setIsTradeModal(false);
    };

    useEffect(() => {
        fetchData(pageNum);
      }, [dispatch, pageNum]);
    
    useEffect(() => {
        setPageNum(1);
    }, [isMobile]);

    // 기존 지갑 주소 가지고 있는지 내 정보 조회
    useEffect(() => {
        dispatch(userInfoApi());
        setIsReceive(false);
    }, [dispatch]);

    // gmmt 잔액 조회
    useEffect(() => {
        dispatch(dashBoardApi());
    }, []);

    // 모달 오픈시 스크롤 x
    useEffect(() => {
        document.body.style.overflow = isReceive || isSend ? 'hidden' : 'auto';
    }, [isReceive, isSend]);

    return (
        <>
            {status === STATUSES.LOADING || isLoading && <Loading />}
            {
                isOtpModal &&
                <ModalPw
                    isOpen={isOtpModal}
                    closeModal={() => setIsOtpModal(false)}
                    modalOtp={modalOtp}
                    setModalOtp={setModalOtp}
                    otpModalBtn={otpLastClick}
                    handleReset={() => setModalOtp('')}
                    handleBackspace={() => setModalOtp((prev) => prev.slice(0, -1))}
                    zIndex={52}
                    title="구글 OTP 코드 입력"
                    modalType="otp"
                />
            }
            {
                isTradeModal &&
                <ModalPw
                    isOpen={isTradeModal}
                    closeModal={() => setIsTradeModal(false)}
                    modalOtp={modalTrade}
                    setModalOtp={setModalTrade}
                    otpModalBtn={handleTradeOtpSubmit}
                    handleReset={() => setModalTrade('')}
                    handleBackspace={() => setModalTrade((prev) => prev.slice(0, -1))}
                    zIndex={52}
                />
            }
            {
                isReceive &&
                <>
                    <ModalOverlay onClick={() => { setIsReceive(false); }} />
                    <ModalContainer>
                        <ClostBtn onClick={() => { setIsReceive(false); }}>
                            <img src='./../img/common_close_w_btn.png' />
                        </ClostBtn>
                        <ModalTitle>GMMT Coin 받기</ModalTitle>
                        <ModalWrap className='receive'>
                            <ModalIconWrap>
                                <ModalIcon>
                                    <img src='./../img/common_gmmt_logo.png' />
                                </ModalIcon>
                            </ModalIconWrap>
                            <ModalContentTitle className='wallet'>GMMT 지갑</ModalContentTitle>
                            <ModalOtpWrap>
                                <div>
                                    <QrCode url={data?.walletAddress} />
                                </div>
                                <ModalOtpTxt>
                                    <ModalOtpBox>
                                        <p>{data?.walletAddress ? isMobile ? shortenTxt(data?.walletAddress) : data?.walletAddress : ''}</p>
                                        <ModalOtpCopy onClick={() => { handleCopy(data.walletAddress) }}>
                                            <img src='./../img/common_copy_ico.png' alt='Copy OTP Key' />
                                        </ModalOtpCopy>
                                    </ModalOtpBox>
                                    <ModalOtpDesc>
                                        <p>입금 주소와 일치하지 않는 코인을 전송할 경우 <span>해당 코인을 찾을 수 없습니다.</span></p>
                                        <p>입금 코인 종류를 반드시 확인 하세요.</p>
                                    </ModalOtpDesc>
                                </ModalOtpTxt>
                            </ModalOtpWrap>
                        </ModalWrap>
                    </ModalContainer>
                </>
            }
            {
                isSend &&
                <>
                    <ModalOverlay onClick={() => { setIsSend(false); setIsNext(false); }} />
                    <ModalContainer>
                        <ClostBtn onClick={() => { setIsSend(false); setIsNext(false); }}>
                            <img src='./../img/common_close_w_btn.png' />
                        </ClostBtn>
                        <ModalTitle>GMMT Coin 보내기</ModalTitle>
                        <ModalWrap ref={modalTopRef}>
                            {
                                !isNext ?
                                    <>
                                        <ModalIconWrap>
                                            <ModalIcon>
                                                <img src='./../img/common_gmmt_logo.png' />
                                            </ModalIcon>
                                        </ModalIconWrap>
                                        <ModalContentTitle className='wallet'>GMMT 지갑</ModalContentTitle>
                                        <ModalOtpBox className='balance'>
                                            <span>나의 GMMT 잔고</span>
                                            <ModalCoinValue>
                                                <p>{addCommasToNumber(formatBalance(dashboardData?.gmmtBalance))}</p>
                                                <span>GMMT</span>
                                            </ModalCoinValue>
                                        </ModalOtpBox>
                                        <ModalContentTitle className='borderB'>받는 지갑 주소</ModalContentTitle>
                                        <ModalAuthInputWrap>
                                            <ModalAuthInput
                                                ref={addressRef}
                                                style={{cursor:'auto'}}
                                                placeholder='받는 지갑 주소 입력'
                                                type='text'
                                                value={receiveAddress}
                                                onChange={(e) => setReceiveAddress(e.target.value)} />
                                        </ModalAuthInputWrap>
                                        <ModalContentTitle className='borderB'>최근</ModalContentTitle>
                                        <ModalContent $nodata={latestWithdrawals?.length === 0}>
                                            {
                                                latestWithdrawals?.length === 0 ?
                                                <p>최근 내역이 없습니다.</p>
                                                :
                                                latestWithdrawals?.map((item,index)=>{
                                                    return(
                                                        <ModalRecentWrap key={index}>
                                                            <ModalRecentIcon>
                                                                <img src='./../img/common_gmmt_logo.png' />
                                                            </ModalRecentIcon>
                                                            <p>{isMobile ? shortenTxtMobile(item.txid || '') : shortenTxtPc(item.txid || '')}</p>
                                                        </ModalRecentWrap>
                                                    )
                                                })
                                            } 
                                        </ModalContent>
                                        <ModalContentTitle className='borderB'>출금수량</ModalContentTitle>
                                        <ModalAuthInputWrap>
                                            <ModalAuthInput
                                                ref={cntRef}
                                                style={{cursor:'auto'}}
                                                placeholder='출금 수량 입력'
                                                value={cnt}
                                                type='text'
                                                onChange={handleCntChange} />
                                            <ContentBtn onClick={maxCnt}>최대수량</ContentBtn>
                                        </ModalAuthInputWrap>
                                    </>
                                    :
                                    <>
                                        <ModalFrom>
                                            <p>From</p>
                                            <ModalFromLine>
                                                <span>잔고</span>
                                                <span className='line'></span>
                                                <span>{addCommasToNumber(formatBalance(dashboardData?.gmmtBalance))} GMMT</span>
                                            </ModalFromLine>
                                        </ModalFrom>
                                        <ModalAddress>
                                            <ModalRecentIcon>
                                                <img src='./../img/common_gmmt_logo.png' />
                                            </ModalRecentIcon>
                                            <ModalAddressTxt>{data?.walletAddress || ''}</ModalAddressTxt>
                                        </ModalAddress>
                                        <ModalFrom>
                                            <p>To</p>
                                        </ModalFrom>
                                        <ModalAddress>
                                            <ModalRecentIcon>
                                                <img src='./../img/common_gmmt_logo.png' />
                                            </ModalRecentIcon>
                                            <ModalAddressTxt>{receiveAddress}</ModalAddressTxt>
                                        </ModalAddress>
                                        <ModalContentTitle className='price'>
                                            <p>금액</p>
                                            <PriceTxt>
                                                <p>{cnt}</p>
                                                <p>GMMT</p>
                                            </PriceTxt>
                                        </ModalContentTitle>
                                        <PriceWrap>
                                            <p>예상 가스요금</p>
                                            <p>{withdrawData?.withdrawFee || ''} GMMT</p>
                                        </PriceWrap>
                                        <PriceWrap className='total'>
                                            <p>총</p>
                                            <p>{((cnt - withdrawData?.withdrawFee) || 0).toFixed(4)} GMMT</p>
                                        </PriceWrap>
                                        <ModalContentTitle className='borderB'>인증</ModalContentTitle>
                                        <ModalAuthWrap>
                                            <ModalAuthInputWrap style={{ margin: '0' }}>
                                                <ModalAuthInput
                                                    readOnly
                                                    onClick={handleTradePasswordClick}
                                                    placeholder='거래 비밀번호 입력'
                                                    value={tradePw}
                                                    type={eye[0] ? 'text' : 'password'}
                                                    onChange={(e) => setTradePw(e.target.value)} />
                                                <img src={eye[0] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={() => { toggleEye(0) }} />
                                            </ModalAuthInputWrap>
                                        </ModalAuthWrap>
                                        <ModalAuthWrap>
                                            <ModalAuthInputWrap style={{ margin: '0' }}>
                                                <ModalAuthInput
                                                    readOnly
                                                    onClick={handleOtpInput}
                                                    placeholder='구글 OTP 코드 입력'
                                                    value={otp}
                                                    type={eye[1] ? 'text' : 'password'}
                                                    onChange={(e) => setOtp(e.target.value)} />
                                                <img src={eye[1] ? './../img/common_view_ico@3x.png' : './../img/common_viewoff_ico@3x.png'} onClick={() => { toggleEye(1) }} />
                                            </ModalAuthInputWrap>
                                        </ModalAuthWrap>
                                    </>
                            }
                        </ModalWrap>
                        <ModalAuthBtnWrap>
                            <ModalAuthBtn onClick={() => { setIsSend(false); setIsNext(false); }}>닫기</ModalAuthBtn>
                            <ModalAuthBtn className='next' onClick={isNext ? handleSubmit : handleSendNext}>{buttonTxt}</ModalAuthBtn>
                        </ModalAuthBtnWrap>
                    </ModalContainer>
                </>
            }
            <Header />
            <LayoutBg>
                <MainLayout>
                    <Title>Wallet</Title>
                    <CreditWrap>
                        <CreditIconWrap>
                            <CreditIcon>
                                <img src='./../img/common_gmmt_logo_big.png' />
                            </CreditIcon>
                        </CreditIconWrap>
                        <CreditRight>
                            <CreditRightTitleWrap>
                                <CreditIcon className='icon'>
                                    <img src='./../img/common_gmmt_logo.png' />
                                </CreditIcon>
                                <CreditRightTitle>GMMT</CreditRightTitle>
                            </CreditRightTitleWrap>
                            <CreditBorder>
                                <CreditBorderTitle>나의 GMMT 잔고</CreditBorderTitle>
                                <CreditBorderCnt>
                                    <p>{addCommasToNumber(formatBalance(dashboardData?.gmmtBalance))}</p>
                                    <span>GMMT</span>
                                </CreditBorderCnt>
                            </CreditBorder>
                            <CreditBtnWrap>
                                <CreditBtn onClick={() => { handleReceive() }}>
                                    <img src='./../img/ico_receive.png' />
                                    <p>받기</p>
                                </CreditBtn>
                                <CreditBtn onClick={() => { handleSend() }}> 
                                {/* <CreditBtn onClick={() => {alert("준비 중입니다.") }}> */}
                                    <img src='./../img/ico_send.png' />
                                    <p>보내기</p>
                                </CreditBtn>
                            </CreditBtnWrap>
                        </CreditRight>
                    </CreditWrap>
                    <ContentTitle>거래내역</ContentTitle>
                    <TableContainer>
                        {
                           status === STATUSES.SUCCEEDED &&
                           withdrawData?.withdrawList?.content?.length === 0 ?
                           <TableNoData>거래 내역이 없습니다.</TableNoData>
                            :
                            <Table>
                                <thead>
                                    <tr>
                                        <th>NO</th>
                                        <th>일시</th>
                                        <th>거래금액</th>
                                        <th>Txid</th>
                                        <th>상태</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        withdrawData?.withdrawList?.content && withdrawData?.withdrawList?.content?.length > 0 &&
                                        withdrawData?.withdrawList?.content?.map((item, index) => {
                                            const postNumber = withdrawData?.withdrawList?.totalElements - (pageNum - 1) * pageSize - index;
                                            return(
                                                <tr key={index}>
                                                    <td style={{ minWidth: '65px' }}>{postNumber || ''}</td>
                                                    <td style={{ minWidth: '145px' }}>{item.createdAt || ''}</td>
                                                    <td style={{ minWidth: '150px' }}>
                                                        <TableWalletAddress>
                                                            <p>{item.mode === "payment" ? "+" : item.mode === "withdraw" ? "-" : ""}</p>
                                                            <p>
                                                                {
                                                                    parseFloat(item.amount) % 1 === 0 
                                                                    ? addCommasToNumber((parseFloat(item.amount)).toFixed(0))
                                                                    : addCommasToNumber(parseFloat(item.amount).toFixed(4))
                                                                } 
                                                            </p>
                                                        </TableWalletAddress>
                                                    </td>
                                                    <td style={{ minWidth: '220px' }}>
                                                        <TableTrxId>
                                                            <p onClick={()=>{{window.location.href=`https://scan.gmmtchain.io/tx/` + `${item.txid}`}}}>{item.txid || ''}</p>
                                                        </TableTrxId>
                                                    </td>
                                                    <td style={{ minWidth: '180px' }}>
                                                        <TableState>
                                                            {/* {
                                                                item.flag === 1 ?
                                                                <StateApproved>완료</StateApproved>
                                                                : 
                                                                <StateFailed>실패</StateFailed>
                                                            } */}
                                                            {
                                                                item.flag === 0 ?
                                                                <StatePending>신청</StatePending>
                                                                :
                                                                item.flag === 1 ?
                                                                <StateApproved>완료</StateApproved>
                                                                : 
                                                                <StateFailed>실패</StateFailed>
                                                            }
                                                        </TableState>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                        {/* 모바일 출금 내역 */}
                        {
                            isMobile &&
                            detailList?.map((item, index) => {
                                return (
                                    <MtableWrap key={index}>
                                        <MtableTop>
                                            <MtableDate>{item.createdAt || ''}</MtableDate>
                                            {/* {
                                                item.flag === 1 ? 
                                                <MtableStateApproved>완료</MtableStateApproved>
                                                :
                                                <MtableStateFailed>실패</MtableStateFailed>
                                            } */}
                                            {
                                                item.flag === 0 ? 
                                                <MtableStatePending>신청</MtableStatePending>
                                                :
                                                item.flag === 1 ? 
                                                <MtableStateApproved>완료</MtableStateApproved>
                                                :
                                                <MtableStateFailed>실패</MtableStateFailed>
                                            }
                                        </MtableTop>
                                        <MtableBottom>
                                            <MtableCnt>
                                                <p>거래금액</p>
                                                <div style={{display:"flex", alignItems:"center",gap:"5px"}}>
                                                    <p>{item.mode === "payment" ? "+" : item.mode === "withdraw" ? "-" : ""}</p>
                                                    <span>
                                                        {
                                                            parseFloat(item.amount) % 1 === 0 
                                                            ? addCommasToNumber((parseFloat(item.amount)).toFixed(0))
                                                            : addCommasToNumber(parseFloat(item.amount).toFixed(4))
                                                        } 
                                                    </span>
                                                </div>
                                            </MtableCnt>
                                            <MtableCnt>
                                                <p>Txid</p>
                                                <span style={{cursor:'pointer'}} onClick={()=>{{window.location.href=`https://scan.gmmtchain.io/tx/` + `${item.txid}`}}}>{shortenTxt(item.txid || '')}</span>
                                            </MtableCnt>
                                        </MtableBottom>
                                    </MtableWrap>
                               )
                            })
                        }
                    </TableContainer>
                    <Pagination
                        onChange={(num) => setPageNum(num)}
                        data={withdrawData?.withdrawList.content && withdrawData?.withdrawList}
                        putPageNum={pageNum}
                    />
                </MainLayout>
                <Footer />
            </LayoutBg>
        </>
    )
}

export default CreditList
