import React from 'react'
import styled from 'styled-components';

const FooterContainer = styled.div`
  width: 100%;
  padding: 43px 0;
  position: ${(props)=>props.$fixed ? 'fixed' : 'relative'};
  bottom: ${(props)=>props.$fixed && '0'};
  left: ${(props)=>props.$fixed && '50%'};
  transform: ${(props)=>props.$fixed && 'translateX(-50%)'};
  display: flex; 
  align-items: center;
  justify-content: center;
  height: 100px;
  p{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.026rem;
    text-align: center;
    color: #AAAAAA;
  }
  @media (max-width:780px){
    padding: 32px 20px;
    height: 80px;
  }
`

function Footer(props) {
  
  return (
    <FooterContainer {...props}>
      <p>© 2024 GMMTSTAKING. All rights reserved.</p>
    </FooterContainer>
  )
}

export default Footer