
import React, { useEffect, useState, useCallback } from 'react';

export const randomNumbers = () => {

    let randomNumbers = ""

    for (let i = 0; i < 5; i++) {
        const random = Math.floor(Math.random() * 10);
        randomNumbers += random
    }

    return randomNumbers
}

export function useOutsideClick(callback, refs) {

  const [isInside, setIsInside] = useState(false);

  const clickOutsideCheck = useCallback((e) => {
    const clickCheck = refs.some(ref => {
      return ref.current && ref.current.contains(e.target) // 하나라도 true면 인사이드 클릭
    })
    // console.log(clickCheck)
    setIsInside(clickCheck)
  }, [refs, setIsInside])

  useEffect(() => {

    const clickOutside = () => {
      if (!isInside) {
        callback()
      }
    }

    document.addEventListener('mousedown', clickOutsideCheck) // 클릭해서 갑자기 사라지는 요소가 있으면 문제생김 mousedown할때 미리 검사하기
    document.addEventListener('mouseup', clickOutside)

    return () => {
      document.removeEventListener('mousedown', clickOutsideCheck)
      document.removeEventListener('mouseup', clickOutside)
    }

  }, [callback, clickOutsideCheck, isInside])

}

// 3자리 마다 콤마 처리
export const addCommasToNumber = (number) => {
  if (number === undefined || number === null) {
    return '0.0000';
  }

  const findDot = number.toString().includes('.');

  if (findDot) {
    let [jeongsu, sosiuuu] = number.toString().split('.');

    // 소수점 이하 4자리로 맞추기
    sosiuuu = sosiuuu.padEnd(4, '0');

    return jeongsu.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + sosiuuu;
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// utc 시간 yyyy-mm-dd
export const getUtcTimeYmd = (time) => {
  const date = new Date(time); 
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// utc 시간 yyyy-mm-dd hh:mm
export const getUtcTimeYmdHm = (time) => {
  const date = new Date(time); 
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

// utc 시간 yyyy-mm-dd hh::mm:ss
export const getUtcTimeYmdHms = (time) => {
  const date = new Date(time);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const localDateTimeToText = (localDateTime) => {
  const dateTime = new Date(localDateTime);

// 날짜와 시간을 포맷
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const seconds = String(dateTime.getSeconds()).padStart(2, '0');

  // 전체 포맷된 문자열
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// 복사 클릭 함수
export const handleCopy = async (copy) => {
  try {
      const textCopy = document.createElement('textarea');

      textCopy.innerText = copy;
      document.body.appendChild(textCopy);

      textCopy.select();
      document.execCommand('copy');

      textCopy.remove();

      alert('클립보드에 복사되었습니다.');
  } catch (err) {
      console.error('OTP 키 복사 실패:', err);
  }
};

// 지갑주소 , trxId 중간 ... 생략
export const shortenTxt = (str) => {
  if (str.length <= 10) {
      return str;
  }
  const start = str.slice(0, 8); // 맨 앞 8글자
  const end = str.slice(-4); // 맨 뒤 4글자
  return `${start}...${end}`;
};

//pc 는 16,16 
export const shortenTxtPc = (str) => {
  if (str.length <= 10) {
      return str;
  }
  const start = str.slice(0, 16); // 맨 앞 16글자
  const end = str.slice(-16); // 맨 뒤 16글자
  return `${start} ... ${end}`;
};

//모바일은 8,8
export const shortenTxtMobile = (str) => {
  if (str.length <= 10) {
      return str;
  }
  const start = str.slice(0, 8); // 맨 앞 8글자
  const end = str.slice(-8); // 맨 뒤 8글자
  return `${start} ... ${end}`;
};

// gmmtBalance 소수점 4자리로 format
export const formatBalance = (value) => {
  const balance = value ?? 0;

  // 소수점 4자리에서 내림
  const roundedBalance = Math.floor(balance * 10000) / 10000; 

  // 소수점 4자리로 return 
  return roundedBalance.toFixed(4) === '0.0000' ? '0' : roundedBalance.toFixed(4);
};

