const API_URL = {
    LOGIN: '/api/user/login',
    TREE: '/api/user/referral_organization',
    SMS_SEND: '/api/user/smsAuthentication',
    SMS_CHECK: '/api/user/smsAuthenticationConfirm',
    JOIN: '/api/user/signUp',
    RESET: '/api/user/passwordReset',
    POPUP: '/api/page/popup',
    SEND_EMAIL: '/api/user/emailAuthentication',
    CHECK_EMAIL: '/api/user/emailAuthenticationConfirm',
    MYINFO_UPDATE : '/api/user/myInfoUpdate',
    FAVICON_UPDATE : '/api/page/setting',
    DASHBOARD : 'api/page/basic',
    GET_QR_CODE : 'api/user/qrCode',
    POST_OTP : 'api/user/validate',
    LOGIN_USER_INFO : '/api/user/loginUserInfo',
    READ_NOTIFICATION : 'api/page/readNotification',
    BOARD_LIST : 'api/board/getPostList',
    BOARD_DETAIL : 'api/board/getPostDetail',
    WITHDRAW_APPLY : 'api/withdraw/apply',
    WITHDRAW_LIST : 'api/withdraw/list',
    WALLET_CREATE : '/api/user/createWallet',
    STAKING_REQUEST : '/api/staking/request',
    STAKING_LIST : '/api/staking/list',
    STAKING_CANCEL : '/api/staking/cancel',
    SWAP_LIST: '/api/swap/swapList',
    SWAP_SCREEN: '/api/swap/swapRequestScreen',
    SWAP_REQUEST: '/api/swap/swapRequest',
    PROFIT_LIST : '/api/page/profit-list',
    PROFIT_DETAIL_LIST : '/api/page/profit-week-detail',
    STAKING_PRINCIPAL_RECEIVE  : '/api/staking/principal-recive',
    STAKING_PERCENT : 'api/staking/staking-percent'
};

export default API_URL;