import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000A6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 475px;
  transform: translateX(-50%) translateY(-50%);
  background-color: #222222;
  border-radius: 10px;
  padding: 48px 30px 40px 30px;
  z-index: 51;
  @media (max-width:780px){
    width: 320px;
    padding: 35px 20px 30px 20px;
  }
`

const CloseBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 31px;
  cursor: pointer;
  @media (max-width:780px){
    right: 12px;
  }
`

const ModalTitle = styled.div`
  font-weight: 700;
  color: #fff;
  font-size: 1.75rem;
  line-height: 2.051rem;
  text-align: center;
  @media (max-width:780px){
    font-size: 1.5rem;
    line-height: 1.758rem;
  }
`

const ModalCnt = ({ isOpen, closeModal, title, children }) => {
  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={closeModal} />
      <ModalContainer>
        <CloseBtn onClick={closeModal}>
          <img src='./../img/common_close_w_btn.png' alt="Close" />
        </CloseBtn>
        <ModalTitle>{title}</ModalTitle>
        {children}
      </ModalContainer>
    </>
  );
};

export default ModalCnt;
