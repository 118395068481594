export const ROUTER_PATHS = Object.freeze({
    HOME: '/home',
    LOGIN: '/',
    ERROR: '/error',
    NOT_FOUND: '/not-found',
    JOIN: '/join',
    RESET: '/reset',
    DASHBOARD : '/dashBoard',
    MYINFO : '/myInfo',
    VILAGE: '/vilage',
    LINE_TREE: '/line-tree',
    BOARD: '/board',
    BOARD_DETAIL: '/boardDetail',
    STAKING_REQUEST: '/stakingRequest',
    STAKING_LIST: '/stakingList',
    SWAP_REQUEST: '/swapRequest',
    SWAP_LIST: '/swapList',
    STAKING_CANCEL: '/stakingCancel',
    WALLET_MAIN : '/wallet-main',
    CREDIT_LIST : '/credit-list',
    PROFIT_LIST : '/profitList',
    PROFIT_DETAIL_LIST : '/profitDetailList'
});
