import { createSlice } from '@reduxjs/toolkit';

import log from 'loglevel';
import API_URL from '../../constants/apiUrl';
import REDUX_STATUS from '../../constants/reduxStatus';
import { createApiThunk } from '../../utils/apiThunk';

export const stakingRequestApi = createApiThunk('staking/request', API_URL.STAKING_REQUEST, 'post');
export const stakingRequestListApi = createApiThunk('staking/requestList', API_URL.STAKING_LIST, 'get');
export const stakingCancelApi = createApiThunk('staking/requestCancel', API_URL.STAKING_CANCEL, 'post');
export const stakingPrincipalReceiveApi = createApiThunk('staking/principalReceive', API_URL.STAKING_PRINCIPAL_RECEIVE, 'post');
export const stakingPercentApi = createApiThunk('staking/stakingPercent', API_URL.STAKING_PERCENT, 'get');

const stakingSlice = createSlice({

    name: 'staking',
    initialState: {
        status: 'idle',
        error: null,
        data: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(stakingRequestApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(stakingRequestApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(stakingRequestApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(stakingRequestListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(stakingRequestListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(stakingRequestListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(stakingCancelApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(stakingCancelApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(stakingCancelApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(stakingPrincipalReceiveApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(stakingPrincipalReceiveApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(stakingPrincipalReceiveApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(stakingPercentApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(stakingPercentApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(stakingPercentApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            });
    },
});

export default stakingSlice.reducer;
