// Loading.js
import React from 'react';
import styled from 'styled-components';

const LoadingStyled = styled.div`

    position: fixed;
    top: 0; bottom: 0; right: 0; left: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex; justify-content: center;
    align-items: center;
    z-index: 9999;

    img {

      @keyframes spin {
        from {
          transform: rotate(0deg);  /* 시작할 때 회전 각도 */
        }
        to {
          transform: rotate(360deg);  /* 끝날 때 회전 각도 */
        }
      }

       /* width: 100px; height: 100px; */
       animation: spin 0.7s linear infinite;
    }

`;

const Loading = () => (
  <LoadingStyled className="loading-overlay">
    <img src={process.env.PUBLIC_URL + '/img/common_loading_img.png'} />
  </LoadingStyled>
);

export default Loading;